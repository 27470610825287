import React from 'react'

const LoginPassword = () => {
  return (
   <>
   
   <section class="d-flex justify-content-center align-items-center h-100">
      <div class="lgoinSignMain">
        <div class="d-flex justify-content-center">
          <a href="/">
            <img src="img/logo-dark.png" width="100" alt="9 Gem"/>
          </a>
        </div>
        <div class="card my-3">
          <div class="card-body">
            <h5><i class="ci-unlocked me-2 mt-n1"></i>Sign in</h5>
            <p class="mb-3 font14"> <span>8284040490</span> <a href="/login" class="text-danger">Change</a></p>
            <form>
              <div class="mb-3">
                <div class="d-flex justify-content-between">
                  <label class="form-label font16">Password</label>
                  <a href="/forgot-password" class="text-muted">Forgot Password</a>
                </div>
                <div class="password-toggle">
                  <input class="form-control" type="password"/>
                  <label class="password-toggle-btn" aria-label="Show/hide password">
                    <input class="password-toggle-check" type="checkbox"/>
                    <span class="password-toggle-indicator"></span>
                  </label>
                </div>
              </div>
              <a href="/login-password" class="btn btn-primary btn-shadow d-block w-100" type="submit">Sign In</a>
            </form>
          </div>
        </div>
        <div class="text-center">
          <p class="mb-3 font14"> Or </p>
          <p class="mb-0"><a href="/register" class="btn btn-outline-dark btn-shadow d-block w-100" type="submit">Get an OTP on 8284040490</a></p>
        </div>
      </div>
    </section>

   </>
  )
}

export default LoginPassword
import React from 'react';
import Navbar from '../Components/Navbar/Navbar';
import NavbarTwo from '../Components/NavbarTwo/NavbarTwo';
import FooterA from '../Components/FooterA/FooterA';

const BlogDetails = () => {
  return (
   <>
   <Navbar/>
   <NavbarTwo/>
    <section class="bgBlue pt-4 pb-4">
        <div class="container pt-2 pb-3 pt-lg-3 pb-lg-4">
          <div class="d-lg-flex justify-content-between">
              <div class="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
                    <li class="breadcrumb-item">
                      <a class="text-nowrap" href="/"><i class="ci-home"></i>Home</a>
                    </li>
                    <li class="breadcrumb-item text-nowrap"><a href="blog">Blog</a></li>
                    <li class="breadcrumb-item text-nowrap active" aria-current="page">Blog Detail</li>
                  </ol>
                </nav>
              </div>
              <div class="order-lg-1 pe-lg-4 text-center text-lg-start">
                <h1 class="h3 text-white mb-0">Blog Detail</h1>
              </div>
          </div>
        </div>
      </section>


      <section class="py-5">
        <div class="container">
            <div class="row  mt-md-2">

              <div class="col-lg-8">
                <div class="d-flex flex-wrap justify-content-between align-items-center pb-4 mt-n1">
                  <div class="d-flex align-items-center fs-sm mb-2">
                    <a class="blog-entry-meta-link" href="#">
                      <div class="blog-entry-author-ava">
                        <img src="img/home/blog/03.jpg" alt="Hardeep Singh"/>
                      </div>Hardeep Singh
                    </a>
                    <span class="blog-entry-meta-divider"></span>
                    <a class="blog-entry-meta-link" href="#">May 06</a>
                  </div>
                  <div class="fs-sm mb-2">
                    <a class="blog-entry-meta-link text-nowrap" href="#comments" data-scroll>
                      <i class="ci-message"></i>15
                    </a>
                  </div>
                </div>
                <div class="row pb-2">
                  <div class="col-sm-12">
                    <div>
                      <img src="img/home/blog/blog-1.jpg" class="d-blog img-fluid w-100 radius8" alt="blog image"/>
                    </div>
                  </div>
                </div>
                <p class="mt-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Ut enim ad minima veniam, quis nostrum exercitationem occaecat cupidatat non proident.</p>
                <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est.</p>

                <hr class="my-3 my-sm-5"/>

                <div class="pt-2">
                  <h2 class="h4">Comments<span class="badge bg-secondary fs-sm text-body align-middle ms-2">3</span></h2>

                  <div class="d-flex align-items-start py-4 border-bottom">
                    <img class="rounded-circle" src="img/home/blog/03.jpg" width="50" alt="James Anderson"/>
                    <div class="ps-3">
                      <div class="d-flex justify-content-between align-items-center mb-2">
                        <h6 class="fs-md mb-0">James Anderson</h6><a class="nav-link-style fs-sm fw-medium" href="#"><i class="ci-reply me-2"></i>Reply</a>
                      </div>
                      <p class="fs-md mb-1">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat cupidatat non proident, sunt in culpa qui.</p><span class="fs-ms text-muted"><i class="ci-time align-middle me-2"></i>May 7, 2024</span>
                 
                      <div class="d-flex align-items-start border-top pt-4 mt-4">
                        <img class="rounded-circle" src="img/home/blog/03.jpg" width="50" alt="Michael Davis"/>
                        <div class="ps-3">
                          <div class="d-flex justify-content-between align-items-center mb-2">
                            <h6 class="fs-md mb-0">Michael Davis</h6>
                          </div>
                          <p class="fs-md mb-1">Egestas sed sed risus pretium quam vulputate dignissim. A diam sollicitudin tempor id eu nisl. Ut porttitor leo a diam. Bibendum at varius vel pharetra vel turpis nunc.</p><span class="fs-ms text-muted"><i class="ci-time align-middle me-2"></i>Sep 13, 2024</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="d-flex align-items-start py-4">
                    <img class="rounded-circle" src="img/home/blog/03.jpg" width="50" alt="Benjamin Miller"/>
                    <div class="ps-3">
                      <div class="d-flex justify-content-between align-items-center mb-2">
                        <h6 class="fs-md mb-0">Benjamin Miller</h6><a class="nav-link-style fs-sm fw-medium" href="#"><i class="ci-reply me-2"></i>Reply</a>
                      </div>
                      <p class="fs-md mb-1">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat cupidatat non proident, sunt in culpa qui.</p><span class="fs-ms text-muted"><i class="ci-time align-middle me-2"></i>Aug 15, 2024</span>
                    </div>
                  </div>
                  <div class="card border-0 shadow mt-2 mb-4">
                    <div class="card-body">
                      <div class="d-flex align-items-start">
                        <img class="rounded-circle" src="img/home/blog/03.jpg" width="50" alt="Mary Alice"/>
                        <form class="w-100 needs-validation ms-3">
                          <div class="mb-3">
                            <textarea class="form-control" rows="4" placeholder="Write comment..."></textarea>
                          </div>
                          <button class="btn btn-primary btn-sm" type="submit">Post comment</button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                
              </div>


              <aside class="col-lg-4">
                <div class="offcanvas offcanvas-collapse offcanvas-end border-start ms-lg-auto" id="blog-sidebar" style={{maxWidth:'22rem'}}>
                  <div class="offcanvas-header align-items-center shadow-sm">
                    <h2 class="h5 mb-0">Sidebar</h2>
                    <button class="btn-close ms-auto" type="button" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                  </div>
                  <div class="offcanvas-body py-grid-gutter py-lg-1 px-lg-4" data-simplebar data-simplebar-auto-hide="true">
                    <div class="widget widget-links mb-grid-gutter pb-grid-gutter border-bottom mx-lg-2">
                      <h3 class="widget-title">Blog categories</h3>
                      <ul class="widget-list">
                        <li class="widget-list-item">
                          <a class="widget-list-link d-flex justify-content-between align-items-center" href="#">
                            <span>Category 1</span>
                            <span class="fs-xs text-muted ms-3">18</span>
                          </a>
                        </li>
                        <li class="widget-list-item">
                          <a class="widget-list-link d-flex justify-content-between align-items-center" href="#">
                            <span>Category 2</span>
                            <span class="fs-xs text-muted ms-3">25</span>
                          </a>
                        </li>
                        <li class="widget-list-item">
                          <a class="widget-list-link d-flex justify-content-between align-items-center" href="#">
                            <span>Category 3</span>
                            <span class="fs-xs text-muted ms-3">13</span>
                          </a>
                        </li>
                        <li class="widget-list-item">
                          <a class="widget-list-link d-flex justify-content-between align-items-center" href="#">
                            <span>Category 4</span>
                            <span class="fs-xs text-muted ms-3">7</span>
                          </a>
                        </li>
                        <li class="widget-list-item">
                          <a class="widget-list-link d-flex justify-content-between align-items-center" href="#">
                            <span>Category 5</span>
                            <span class="fs-xs text-muted ms-3">34</span>
                          </a>
                        </li>
                        <li class="widget-list-item">
                          <a class="widget-list-link d-flex justify-content-between align-items-center" href="#">
                            <span>Category 6</span>
                            <span class="fs-xs text-muted ms-3">6</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="widget pb-grid-gutter mx-lg-2">
                      <h3 class="widget-title">Popular tags</h3>
                      <a class="btn-tag me-2 mb-2" href="#">#Genstone</a>
                      <a class="btn-tag me-2 mb-2" href="#">#gems</a>
                      <a class="btn-tag me-2 mb-2" href="#">#Genstone</a>
                      <a class="btn-tag me-2 mb-2" href="#">#gems</a>
                      <a class="btn-tag me-2 mb-2" href="#">#Genstone</a>
                      <a class="btn-tag me-2 mb-2" href="#">#gems</a>
                      <a class="btn-tag me-2 mb-2" href="#">#Genstone</a>
                      <a class="btn-tag me-2 mb-2" href="#">#gems</a>
                    </div>
                  </div>
                </div>
              </aside>
            </div>
           </div>
      </section>
      <FooterA/>
   </>
  )
}

export default BlogDetails
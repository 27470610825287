
import './App.css';
import HomePage from './Pages/HomePage/HomePage';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

import FirstPage from './Pages/FirstPage';
import Product from './Pages/Product';
import ShopSingle from './Pages/ShopSingle';
import ShopCart from './Pages/ShopCart';
import CheckoutReview from './Pages/CheckoutReview';
import CheckoutDetails from './Pages/CheckoutDetails';
import CheckoutComplete from './Pages/CheckoutComplete';
import OrderView from './Pages/OrderView';
import Register from './Pages/Register';
import Login from './Pages/Login';
import LoginPassword from './Pages/LoginPassword';
import ForgotPssword from './Pages/ForgotPssword';
import ForgotPasswordOTP from './Pages/ForgotPasswordOTP';
import Blog from './Pages/Blog';
import BlogDetails from './Pages/BlogDetails';
import AccountWishlist from './Pages/AccountWishlist';
import AccountProfile from './Pages/AccountProfile';
import AccountPaymet from './Pages/AccountPaymet';
import AccountOrders from './Pages/AccountOrders';
import AccountAddress from './Pages/AccountAddress';
function App() {
  return (
   <>
    <Router>
    <Routes>
      <Route   path="/" element={<FirstPage/>}   />
      <Route   path="/products" element={<Product/>}   />
      <Route   path="/shop-single" element={<ShopSingle/>}   />
      <Route   path="/shop-cart" element={<ShopCart/>}   />
      <Route   path="/checkout-review" element={<CheckoutReview/>}   />
      <Route   path="/checkout-details" element={<CheckoutDetails/>}   />
      <Route   path="/checkout-complete" element={<CheckoutComplete/>}   />
      <Route   path="/order-view" element={<OrderView/>}/>
      <Route   path="/register" element={<Register/>}/>
      <Route   path="/login" element={<Login/>}/>
      <Route   path="/login-password" element={<LoginPassword/>}/>
      <Route   path="/forgot-password" element={<ForgotPssword/>}/>
      <Route   path="/forgot-password-otp" element={<ForgotPasswordOTP/>}/>
      <Route   path="/blog" element={<Blog/>}/>
      <Route   path="/blog-details" element={<BlogDetails/>}/>
      <Route   path="/account-wishlist" element={<AccountWishlist/>}/>
      <Route   path="/account-profile" element={<AccountProfile/>}/>
      <Route   path="/account-payment" element={<AccountPaymet/>}/>
      <Route   path="/account-orders" element={<AccountOrders/>}/>
      <Route   path="/account-address" element={<AccountAddress/>}/>

      
      </Routes>
      </Router>
   
   </>
  );
}

export default App;

import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import IND from "../../Assests/img/home/flags/en.png";
import Français from "../../Assests/img/home/flags/fr.png";
import Deutsch from "../../Assests/img/home/flags/de.png";
import Italiano from "../../Assests/img/home/flags/it.png";
import { Link } from 'react-router-dom';



const Navbar = () => {
  return (
   <>
     <div className="topbar topbar-dark bg-red">
        <div className="container">

          <div className="topbar-text dropdown d-md-none">
            <a className="topbar-link dropdown-toggle" href="#" data-bs-toggle="dropdown">Useful links</a>
            <ul className="dropdown-menu">
              <li>
                <a className="dropdown-item" href="tel:009216223355">
                  <i className="ci-support text-muted me-2"></i>(+91) 92 1622 3355
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="order-tracking.html">
                  <i className="ci-location text-muted me-2"></i>Order tracking
                </a>
              </li>
            </ul>
          </div>

          <div className="topbar-text text-nowrap d-none d-md-inline-block">
            <i className="ci-support"></i><span className="text-muted me-1">Support</span>
            <a className="topbar-link" href="tel:009216223355">(+91) 92 1622 3355</a>
          </div>

          <div className="tns-carousel tns-controls-static d-none d-md-block">
      <div className="tns-carousel-inner" data-carousel-options={{"mode": "gallery", "nav": false}}>
        <div className="topbar-text">Free shipping for orders over $200</div>
        <div className="topbar-text">We return money within 30 days</div>
        <div className="topbar-text">Friendly 24/7 customer support</div>
      </div>
    </div>

          <div className="ms-3 text-nowrap">
            <a className="topbar-link me-4 d-none d-md-inline-block" href="order-tracking.html">
              <i className="ci-location"></i>Order tracking
            </a>
            <div className="topbar-text dropdown disable-autohide">
              <a className="topbar-link dropdown-toggle" href="#" data-bs-toggle="dropdown">
                <img className="me-2" src={IND} width="20" alt="English"/>IND
              </a>
              <ul className="dropdown-menu dropdown-menu-end">
                <li>
                  <a className="dropdown-item pb-1" href="#">
                  <img className="me-2" src={Français} width="20" alt="Français"/>Français</a>
                </li>
                <li>
                  <a className="dropdown-item pb-1" href="#">
                  <img className="me-2" src={Deutsch} width="20" alt="Deutsch"/>Deutsch</a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                  <img className="me-2" src={Italiano} width="20" alt="Italiano"/>Italiano</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
   
   
   </>
  )
}

export default Navbar
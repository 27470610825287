import React from 'react';
import GemP1 from "../Assests/img/home/gemstone/p-1.png"
import GemP2 from "../Assests/img/home/gemstone/p-2.png"
import GemP3 from "../Assests/img/home/gemstone/p-3.png"
import GemP4 from "../Assests/img/home/gemstone/p-4.png"
import  TinySlider  from 'tiny-slider-react';
import Navbar from '../Components/Navbar/Navbar';
import NavbarTwo from '../Components/NavbarTwo/NavbarTwo';
import FooterA from '../Components/FooterA/FooterA';


const settings = {
    items: 2,
    gutter: 16,
    controls: true,
    autoHeight: true,
    responsive: {
      0: { items: 1 },
      480: { items: 2 },
      720: { items: 3 },
      991: { items: 2 },
      1140: { items: 3 },
      1300: { items: 4 },
      1500: { items: 5 },
    },
  };

  const products = [
    {
      imgSrc: GemP1,
      title: 'Amethyst',
      meta: '(1490mg)',
      sku: '40311347889',
      price: 'Rs. 1500',
    },
    {
      imgSrc: GemP3,
      title: 'Citrine',
      meta: '(1490mg)',
      sku: '40311347889',
      price: 'Rs. 2500',
    },
    {
      imgSrc: GemP4,
      title: 'Emerald',
      meta: '(1490mg)',
      sku: '40311347889',
      price: 'Rs. 3500',
    },
    {
      imgSrc: GemP2,
      title: 'Amethyst',
      meta: '(1490mg)',
      sku: '40311347889',
      price: 'Rs. 2700',
    },
    {
      imgSrc: GemP1,
      title: 'Amethyst',
      meta: '(1490mg)',
      sku: '40311347889',
      price: 'Rs. 1500',
    },
    {
      imgSrc: GemP3,
      title: 'Citrine',
      meta: '(1490mg)',
      sku: '40311347889',
      price: 'Rs. 2500',
    },
    {
      imgSrc: GemP4,
      title: 'Emerald',
      meta: '(1490mg)',
      sku: '40311347889',
      price: 'Rs. 3500',
    },
    {
      imgSrc: GemP2,
      title: 'Amethyst',
      meta: '(1490mg)',
      sku: '40311347889',
      price: 'Rs. 2700',
    },
  ];

const ShopSingle = () => {
  return (
<>
<Navbar/>
<NavbarTwo/>
<section class="page-title-overlap bgBlue pt-4 pb-5">
        <div class="container pt-2 pb-3 pt-lg-3 pb-lg-4">
          <div class="d-lg-flex justify-content-between pb-3">
              <div class="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
                    <li class="breadcrumb-item">
                      <a class="text-nowrap" href="/"><i class="ci-home"></i>Home</a>
                    </li>
                    <li class="breadcrumb-item text-nowrap"><a href="/products">Products</a></li>
                    <li class="breadcrumb-item text-nowrap active" aria-current="page">Amethyst (1490MG)</li>
                  </ol>
                </nav>
              </div>
              <div class="order-lg-1 pe-lg-4 text-center text-lg-start">
                <h1 class="h3 text-white mb-0">Amethyst (1490MG)</h1>
              </div>
          </div>
        </div>
      </section>


      <section class="container">
   
        <div class="bgwhite shadow-lg radius8 p-4 my-4">
          <div class="px-lg-3">
            <div class="row">
             
              <div class="col-lg-7 pe-lg-0 pt-lg-4">
                <div class="product-gallery">
                  <div class="product-gallery-preview order-sm-2">
                    <div class="product-gallery-preview-item active" id="first">
                      <img class="image-zoom" src="img/home/product/large/01.jpg" data-zoom="img/home/product/large/01.jpg" alt="Product image"/>
                      <div class="image-zoom-pane"></div>
                    </div>
                    <div class="product-gallery-preview-item" id="second">
                      <img class="image-zoom" src="img/home/product/large/02.jpg" data-zoom="img/home/product/large/02.jpg" alt="Product image"/>
                      <div class="image-zoom-pane"></div>
                    </div>
                    <div class="product-gallery-preview-item" id="third">
                      <img class="image-zoom" src="img/home/product/large/03.jpg" data-zoom="img/home/product/large/03.jpg" alt="Product image"/>
                      <div class="image-zoom-pane"></div>
                    </div>
                  </div>
                  <div class="product-gallery-thumblist order-sm-1">
                    <a class="product-gallery-thumblist-item active" href="#first">
                      <img src="img/home/product/th01.jpg" alt="Product thumb"/>
                    </a>
                    <a class="product-gallery-thumblist-item" href="#second">
                      <img src="img/home/product/th02.jpg" alt="Product thumb"/>
                    </a>
                    <a class="product-gallery-thumblist-item" href="#third">
                      <img src="img/home/product/th03.jpg" alt="Product thumb"/>
                    </a>
                  </div>
                </div>
              </div>
           
              <div class="col-lg-5 pt-4 pt-lg-0">
                <div class="product-details ms-auto pb-3">
                  <h1 class="h2">Amethyst (1490mg)</h1>
                  <h2 class="text-muted font14 text-uppercase">GST and Delivery Charges are included</h2>
                  <h3 class="h5">SKU: 40311347889</h3>
                  <p class="text-muted mb-4">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.
                  </p>
                  <div class="h2 fw-normal text-primary mb-4">Rs. 1500</div>
                  <form class="mb-grid-gutter" method="post">
                    <div class="mb-3 d-flex align-items-center">
                      <select class="form-select me-3" style={{width:'5rem'}}>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                      </select>
                      <a href="/shop-cart" class="btn btn-primary btn-shadow d-block w-100" type="submit">
                        <i class="ci-cart fs-lg me-2"></i>Add to Cart
                      </a>
                    </div>
                  </form>
                  <div class="w-100 mb-4">
                    <button class="btn btn-secondary d-block w-100" type="button">
                      <i class="ci-heart fs-lg me-2"></i>
                      <span class="d-none d-sm-inline">Add to </span>Wishlist
                    </button>
                  </div>
                  <div class="mb-0">
                    <a class="btn-social bs-twitter me-2 mb-2" href="#"><i class="ci-twitter"></i></a>
                    <a class="btn-social bs-facebook me-2 mb-2" href="#"><i class="ci-facebook"></i></a>
                    <a class="btn-social bs-instagram me-2 mb-2" href="#"><i class="ci-instagram"></i></a>
                    <a class="btn-social bs-pinterest me-2 mb-2" href="#"><i class="ci-pinterest"></i></a>
                    <a class="btn-social bs-youtube me-2 mb-2" href="#"><i class="ci-youtube"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="container mb-4 mb-lg-5">
 
        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item"><a class="nav-link p-4 active" href="#details" data-bs-toggle="tab" role="tab">Product details</a></li>
          <li class="nav-item"><a class="nav-link p-4" href="#reviews" data-bs-toggle="tab" role="tab">Additional Information</a></li>
        </ul>
        <div class="tab-content pt-2">
          <div class="tab-pane fade show active" id="details" role="tabpanel">
            <div class="row">
              <div class="col-lg-8">
                <p class="fs-md">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <h3 class="h5 pt-2">Main Benifits</h3>
                <ul class="fs-md textDarkBlue">
                  <li>Nemo enim ipsam voluptatem quia voluptas sit</li>
                  <li>Ut enim ad minima veniam, quis nostrum exercitationem</li>
                  <li>Duis aute irure dolor in reprehenderit in voluptate</li>
                  <li>At vero eos et accusamus et iusto odio dignissimos</li>
                  <li>Omnis voluptas assumenda est omnis dolor</li>
                  <li>Quis autem vel eum iure reprehenderit qui in ea voluptate</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="reviews" role="tabpanel">
            <div class="row">
              <div class="col-md-12">
                <div class="table-responsive">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>Weight </th>
                        <th>Ratti </th>
                        <th>Color</th>
                        <th>Clarity</th>
                        <th>Grade</th>
                        <th>Origin</th>
                        <th>Shape</th>
                        <th>Width</th>
                        <th>Lenght</th>
                        <th>Depth</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1490mg</td>
                        <td>12</td>
                        <td>Grayish White</td>
                        <td>Opaque</td>
                        <td>N</td>
                        <td>India</td>
                        <td>Cabochon</td>
                        <td>10.82</td>
                        <td>12.31</td>
                        <td>8.03</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="gemsCatagory py-5 bgLightGray">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="text-center">
                <h3 class="text-center font40">You may also like</h3>
              </div>
            </div>
          </div>
          <div class="tns-carousel tns-controls-static tns-controls-outside tns-nav-enabled mt-4">
          <TinySlider settings={settings}>
        {products.map((product, index) => (
          <div key={index}>
            <div className="card product-card card-static pb-3">
              <button
                className="btn-wishlist btn-sm"
                type="button"
                data-bs-toggle="tooltip"
                data-bs-placement="left"
                title="Add to wishlist"
              >
                <i className="ci-heart"></i>
              </button>
              <a className="card-img-top d-block overflow-hidden pt-4 pb-2" href="javascript:;">
                <img className="d-block mx-auto" src={product.imgSrc} alt={`p-${index + 1}`} />
              </a>
              <div className="card-body py-2">
                <h3 className="product-title fs-sm text-truncate mb-2">
                  <a href="javascript:;">{product.title}</a>
                  <span className="product-meta">{product.meta}</span>
                </h3>
                <h3 className="product-title fs-sm text-truncate">
                  <span className="product-meta">SKU: {product.sku}</span>
                </h3>
                <div className="textRed font18">{product.price}</div>
              </div>
              <div className="product-floating-btn">
                <button className="btn btn-primary btn-shadow btn-sm" type="button">
                  +<i className="ci-cart fs-base ms-1"></i>
                </button>
              </div>
            </div>
          </div>
        ))}
      </TinySlider>
            </div>
        </div>
      </section>

      <section class="pt-5 pb-4 pb-lg-5">
        <div class="container pt-lg-3">
          <div class="row pt-lg-2 text-left text-md-center">
            <div class="col-lg-3 col-sm-6 mb-grid-gutter">
              <div class="d-inline-flex align-items-center text-start">
                <img src="img/home/gemstone/icon-1.png" width="48" alt="Quality Guarantee"/>
                <div class="ps-3">
                  <h6 class="text-light fontWeight700 font18 mb-1">Fast Shipping</h6>
                  <p class="text-muted mb-0">Dependable delivery <br/> service  for rapid shipping.</p>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6 mb-grid-gutter">
              <div class="d-inline-flex align-items-center text-start">
                <img src="img/home/gemstone/icon-2.png" width="48" alt="Customer Support"/>
                <div class="ps-3">
                  <h6 class="text-light fontWeight700 font18 mb-1">Flexible Payment</h6>
                  <p class="text-muted mb-0">Pay with Multiple Credit <br/> Cards.</p>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6 mb-grid-gutter">
              <div class="d-inline-flex align-items-center text-start">
                <img src="img/home/gemstone/icon-3.png" width="48" alt="Free Updates"/>
                <div class="ps-3">
                  <h6 class="text-light fontWeight700 font18 mb-1">Money Guarantee</h6>
                  <p class="text-muted mb-0">Within 7 days for an <br/> exchange.</p>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6 mb-grid-gutter">
              <div class="d-inline-flex align-items-center text-start">
                <img src="img/home/gemstone/icon-4.png" width="48" alt="Secure Payments"/>
                <div class="ps-3">
                  <h6 class="text-light fontWeight700 font18 mb-1">Online Support</h6>
                  <p class="text-muted mb-0">We are available for <br/> 24x7</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


<FooterA/>
</>
  )
}

export default ShopSingle
import React from 'react';
import Navbar from '../Components/Navbar/Navbar';
import NavbarTwo from '../Components/NavbarTwo/NavbarTwo';
import FooterA from '../Components/FooterA/FooterA';

const Product = () => {
  return (
   <>
   <Navbar/>
   <NavbarTwo/>
   <section class="page-title-overlap bgBlue pt-4 pb-5">
        <div class="container pt-2 pb-3 pt-lg-3 pb-lg-4">
          <div class="d-lg-flex justify-content-between pb-3">
              <div class="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
                    <li class="breadcrumb-item">
                      <a class="text-nowrap" href="/"><i class="ci-home"></i>Home</a>
                    </li>
                    <li class="breadcrumb-item text-nowrap active" aria-current="page">Products</li>
                  </ol>
                </nav>
              </div>
              <div class="order-lg-1 pe-lg-4 text-center text-lg-start">
                <h1 class="h3 text-white mb-0">Products</h1>
              </div>
          </div>
        </div>
      </section>

      <section class="container">
    
        <div class="bgwhite shadow-lg radius8 p-4 my-4">
          <div class="row gy-3 gx-4 justify-content-between align-items-center">
            <div class="col-lg-2 col-md-3 col-sm-5 col-12">
             <div class="d-flex align-items-center">
               <h2 class="font18 mb-0">Filter by Products</h2>
             </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-5 col-12">
              <div class="dropdown"><a class="btn btn-outline-secondary dropdown-toggle w-100" href="#shop-filters" data-bs-toggle="collapse"><i class="ci-filter me-2"></i>Filters</a></div>
            </div>
          </div>
        
          <div class="collapse" id="shop-filters">
            <div class="row pt-4">
              <div class="col-lg-4 col-sm-6">
               
                <div class="card mb-grid-gutter">
                  <div class="card-body px-4">
                    <div class="widget widget-filter">
                      <h3 class="widget-title">Grades</h3>
                      <ul class="widget-list widget-filter-list list-unstyled pt-1" style={{maxHeight:'11.5rem', }} data-simplebar data-simplebar-auto-hide="false">
                        <li class="widget-filter-item d-flex justify-content-between align-items-center mb-2">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="size-xs"/>
                            <label class="form-check-label widget-filter-item-text" for="size-xs">N</label>
                          </div><span class="text-muted">1836</span>
                        </li>
                        <li class="widget-filter-item d-flex justify-content-between align-items-center mb-2">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="size-s"/>
                            <label class="form-check-label widget-filter-item-text" for="size-s">F</label>
                          </div><span class="text-muted">454</span>
                        </li>
                        <li class="widget-filter-item d-flex justify-content-between align-items-center mb-2">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="size-m"/>
                            <label class="form-check-label widget-filter-item-text" for="size-m">P</label>
                          </div><span class="text-muted">1246</span>
                        </li>
                        <li class="widget-filter-item d-flex justify-content-between align-items-center mb-2">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="size-l"/>
                            <label class="form-check-label widget-filter-item-text" for="size-l">SP1</label>
                          </div><span class="text-muted">72</span>
                        </li>
                        <li class="widget-filter-item d-flex justify-content-between align-items-center mb-2">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="size-xl"/>
                            <label class="form-check-label widget-filter-item-text" for="size-xl">SP2</label>
                          </div><span class="text-muted">46</span>
                        </li>
                        <li class="widget-filter-item d-flex justify-content-between align-items-center mb-2">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="size-39"/>
                            <label class="form-check-label widget-filter-item-text" for="size-39">SP3</label>
                          </div><span class="text-muted">112</span>
                        </li>
                        <li class="widget-filter-item d-flex justify-content-between align-items-center mb-2">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="size-40"/>
                            <label class="form-check-label widget-filter-item-text" for="size-40">SP4</label>
                          </div><span class="text-muted">85</span>
                        </li>
                        <li class="widget-filter-item d-flex justify-content-between align-items-center mb-2">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="size-41"/>
                            <label class="form-check-label widget-filter-item-text" for="size-40">SP5</label>
                          </div><span class="text-muted">210</span>
                        </li>
                        <li class="widget-filter-item d-flex justify-content-between align-items-center mb-2">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="size-42"/>
                            <label class="form-check-label widget-filter-item-text" for="size-42">SP6</label>
                          </div><span class="text-muted">57</span>
                        </li>
                        <li class="widget-filter-item d-flex justify-content-between align-items-center mb-2">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="size-43"/>
                            <label class="form-check-label widget-filter-item-text" for="size-43">SP7</label>
                          </div><span class="text-muted">30</span>
                        </li>
                        <li class="widget-filter-item d-flex justify-content-between align-items-center mb-2">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="size-44"/>
                            <label class="form-check-label widget-filter-item-text" for="size-44">SP8</label>
                          </div><span class="text-muted">61</span>
                        </li>
                        <li class="widget-filter-item d-flex justify-content-between align-items-center mb-2">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="size-45"/>
                            <label class="form-check-label widget-filter-item-text" for="size-45">SP9</label>
                          </div><span class="text-muted">23</span>
                        </li>
                        <li class="widget-filter-item d-flex justify-content-between align-items-center mb-2">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="size-46"/>
                            <label class="form-check-label widget-filter-item-text" for="size-46">SP10</label>
                          </div><span class="text-muted">19</span>
                        </li>
                        <li class="widget-filter-item d-flex justify-content-between align-items-center mb-2">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="size-47"/>
                            <label class="form-check-label widget-filter-item-text" for="size-47">SP11</label>
                          </div><span class="text-muted">15</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-sm-6">
           
                <div class="card mb-grid-gutter">
                  <div class="card-body px-4">
                    <div class="widget">
                      <h3 class="widget-title">Ratti</h3>
                      <div class="range-slider" data-start-min="0" data-start-max="106" data-min="0" data-max="106" data-step="1">
                        <div class="range-slider-ui"></div>
                        <div class="d-flex pb-1">
                          <div class="w-50 pe-2 me-2">
                            <div class="input-group input-group-sm">
                              <input class="form-control range-slider-value-min" type="text"/>
                            </div>
                          </div>
                          <div class="w-50 ps-2">
                            <div class="input-group input-group-sm">
                              <input class="form-control range-slider-value-max" type="text"/>
                            </div>
                          </div>
                        </div>
                      </div>
                      <h3 class="widget-title mt-2 invisible d-none d-sm-block">Weight/Carat</h3>
                      
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-sm-6">
             
                <div class="card mb-grid-gutter">
                  <div class="card-body px-4">
                    <div class="widget widget-filter">
                      <h3 class="widget-title">Clarities</h3>
                      <ul class="widget-list widget-filter-list list-unstyled pt-1" style={{maxHeight:'11rem'}} data-simplebar data-simplebar-auto-hide="false">
                        <li class="widget-filter-item d-flex justify-content-between align-items-center mb-2">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="adidas"/>
                            <label class="form-check-label widget-filter-item-text" for="adidas">SI</label>
                          </div><span class="text-muted">425</span>
                        </li>
                        <li class="widget-filter-item d-flex justify-content-between align-items-center mb-2">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="ataylor"/>
                            <label class="form-check-label widget-filter-item-text" for="ataylor">VS</label>
                          </div><span class="text-muted">15</span>
                        </li>
                        <li class="widget-filter-item d-flex justify-content-between align-items-center mb-2">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="armani"/>
                            <label class="form-check-label widget-filter-item-text" for="armani">VVS</label>
                          </div><span class="text-muted">18</span>
                        </li>
                        <li class="widget-filter-item d-flex justify-content-between align-items-center mb-2">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="banana"/>
                            <label class="form-check-label widget-filter-item-text" for="banana">I1</label>
                          </div><span class="text-muted">103</span>
                        </li>
                        <li class="widget-filter-item d-flex justify-content-between align-items-center mb-2">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="bilabong"/>
                            <label class="form-check-label widget-filter-item-text" for="bilabong">IF</label>
                          </div><span class="text-muted">27</span>
                        </li>
                        <li class="widget-filter-item d-flex justify-content-between align-items-center mb-2">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="birkenstock"/>
                            <label class="form-check-label widget-filter-item-text" for="birkenstock">Translucent </label>
                          </div><span class="text-muted">10</span>
                        </li>
                        <li class="widget-filter-item d-flex justify-content-between align-items-center mb-2">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="klein"/>
                            <label class="form-check-label widget-filter-item-text" for="klein">Transparent </label>
                          </div><span class="text-muted">365</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-sm-6">
            
                <div class="card mb-grid-gutter">
                  <div class="card-body px-4">
                    <div class="widget widget-filter">
                      <h3 class="widget-title">Color</h3>
                      <ul class="widget-list widget-filter-list list-unstyled pt-1"  style={{maxHeight:'11rem'}}  data-simplebar data-simplebar-auto-hide="false">
                        <li class="widget-filter-item d-flex justify-content-between align-items-center mb-2">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="adidas"/>
                            <label class="form-check-label widget-filter-item-text" for="adidas">Grayish White </label>
                          </div><span class="text-muted">425</span>
                        </li>
                        <li class="widget-filter-item d-flex justify-content-between align-items-center mb-2">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="ataylor"/>
                            <label class="form-check-label widget-filter-item-text" for="ataylor">Dark Brown</label>
                          </div><span class="text-muted">15</span>
                        </li>
                        <li class="widget-filter-item d-flex justify-content-between align-items-center mb-2">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="armani"/>
                            <label class="form-check-label widget-filter-item-text" for="armani">Yellowish Green</label>
                          </div><span class="text-muted">18</span>
                        </li>
                        <li class="widget-filter-item d-flex justify-content-between align-items-center mb-2">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="banana"/>
                            <label class="form-check-label widget-filter-item-text" for="banana">Reddish Black</label>
                          </div><span class="text-muted">103</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-sm-6">
               
                       <div class="card mb-grid-gutter">
                  <div class="card-body px-4">
                    <div class="widget widget-filter">
                      <h3 class="widget-title">Shapes</h3>
                      <ul class="widget-list widget-filter-list list-unstyled pt-1"  style={{maxHeight:'11rem'}}  data-simplebar data-simplebar-auto-hide="false">
                        <li class="widget-filter-item d-flex justify-content-between align-items-center mb-2">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="adidas"/>
                            <label class="form-check-label widget-filter-item-text" for="adidas">Cabochon </label>
                          </div><span class="text-muted">425</span>
                        </li>
                        <li class="widget-filter-item d-flex justify-content-between align-items-center mb-2">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="ataylor"/>
                            <label class="form-check-label widget-filter-item-text" for="ataylor">Round </label>
                          </div><span class="text-muted">15</span>
                        </li>
                        <li class="widget-filter-item d-flex justify-content-between align-items-center mb-2">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="armani"/>
                            <label class="form-check-label widget-filter-item-text" for="armani">Oval Cabochon</label>
                          </div><span class="text-muted">18</span>
                        </li>
                        <li class="widget-filter-item d-flex justify-content-between align-items-center mb-2">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="banana"/>
                            <label class="form-check-label widget-filter-item-text" for="banana">Oval </label>
                          </div><span class="text-muted">103</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-sm-6">
              
                <div class="card mb-grid-gutter">
                  <div class="card-body px-4">
                    <div class="widget widget-filter">
                      <h3 class="widget-title">Origins</h3>
                      <ul class="widget-list widget-filter-list list-unstyled pt-1" style={{maxHeight:'11rem'}} data-simplebar data-simplebar-auto-hide="false">
                        <li class="widget-filter-item d-flex justify-content-between align-items-center mb-2">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="adidas"/>
                            <label class="form-check-label widget-filter-item-text" for="adidas">Africa </label>
                          </div><span class="text-muted">425</span>
                        </li>
                        <li class="widget-filter-item d-flex justify-content-between align-items-center mb-2">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="ataylor"/>
                            <label class="form-check-label widget-filter-item-text" for="ataylor">India</label>
                          </div><span class="text-muted">15</span>
                        </li>
                        <li class="widget-filter-item d-flex justify-content-between align-items-center mb-2">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="armani"/>
                            <label class="form-check-label widget-filter-item-text" for="armani">Sri Lanka</label>
                          </div><span class="text-muted">18</span>
                        </li>
                        <li class="widget-filter-item d-flex justify-content-between align-items-center mb-2 invisible">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="banana"/>
                            <label class="form-check-label widget-filter-item-text" for="banana">Banana</label>
                          </div><span class="text-muted">103</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="d-sm-flex justify-content-between align-items-center bg-secondary p-4 radius8 mb-grid-gutter">
              <div class="d-sm-flex align-items-center">
                <div class="text-center  position-relative flex-shrink-0">
                  <img class="" src="img/home/gemstone/ruby-gem.png" width="250" alt="ruby-gem"/>
                </div>
                <div class="ps-3">
                  <h3 class="mb-2">Natural Cats Eye</h3>
                  <p class="text-muted mb-0">Available in almost every color of the rainbow, the sapphire is truly an iconic gem.
                    In ancient times, its stunning hues catapulted the stoneâ€™s position
                    and made it the pride of kings, queens, and aristocrats across the world.
                    Available in almost every color of the rainbow, the sapphire is truly an iconic gem.
                    In ancient times, its stunning hues catapulted the stoneâ€™s position
                    and made it the pride of kings, queens, and aristocrats across the world.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      
      <section class="gemsCatagory py-5 bgLightGray">
        <div class="container">
          <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
              <div class="card product-card card-static pb-3">
                <button class="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title="Add to wishlist">
                  <i class="ci-heart"></i>
                </button>
                <a class="card-img-top d-block overflow-hidden pt-4 pb-2" href="/shop-single">
                  <img class="d-block mx-auto" src="img/home/gemstone/p-1.png" alt="p-1"/>
                </a>
                <div class="card-body py-2">
                  <h3 class="product-title fs-sm text-truncate mb-2">
                    <a href="/shop-single">Amethyst</a>
                    <span class="product-meta">(1490mg)</span>
                  </h3>
                  <h3 class="product-title fs-sm text-truncate">
                    <span class="product-meta">SKU: 40311347889</span>
                  </h3>
                  <div class="textRed font18">
                    Rs. 1500
                  </div>
                </div>
                <div class="product-floating-btn">
                  <button class="btn btn-primary btn-shadow btn-sm" type="button">+<i class="ci-cart fs-base ms-1"></i></button>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
              <div class="card product-card card-static pb-3">
                <button class="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title="Add to wishlist">
                  <i class="ci-heart"></i>
                </button>
                <a class="card-img-top d-block overflow-hidden pt-4 pb-2" href="javascript:;">
                  <img class="d-block mx-auto" src="img/home/gemstone/p-3.png" alt="p-3"/>
                </a>
                <div class="card-body py-2">
                  <h3 class="product-title fs-sm text-truncate mb-2">
                    <a href="javascript:;">Citrine</a>
                    <span class="product-meta">(1490mg)</span>
                  </h3>
                  <h3 class="product-title fs-sm text-truncate">
                    <span class="product-meta">SKU: 40311347889</span>
                  </h3>
                  <div class="textRed font18">
                    Rs. 2500
                  </div>
                </div>
                <div class="product-floating-btn">
                  <button class="btn btn-primary btn-shadow btn-sm" type="button">+<i class="ci-cart fs-base ms-1"></i></button>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
              <div class="card product-card card-static pb-3">
                <button class="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title="Add to wishlist">
                  <i class="ci-heart"></i>
                </button>
                <a class="card-img-top d-block overflow-hidden pt-4 pb-2" href="javascript:;">
                  <img class="d-block mx-auto" src="img/home/gemstone/p-4.png" alt="p-4"/>
                </a>
                <div class="card-body py-2">
                  <h3 class="product-title fs-sm text-truncate mb-2">
                    <a href="javascript:;l">Emerlad </a>
                    <span class="product-meta">(1490mg)</span>
                  </h3>
                  <h3 class="product-title fs-sm text-truncate">
                    <span class="product-meta">SKU: 40311347889</span>
                  </h3>
                  <div class="textRed font18">
                    Rs. 3500
                  </div>
                </div>
                <div class="product-floating-btn">
                  <button class="btn btn-primary btn-shadow btn-sm" type="button">+<i class="ci-cart fs-base ms-1"></i></button>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
              <div class="card product-card card-static pb-3">
                <button class="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title="Add to wishlist">
                  <i class="ci-heart"></i>
                </button>
                <a class="card-img-top d-block overflow-hidden pt-4 pb-2" href="javascript:;">
                  <img class="d-block mx-auto" src="img/home/gemstone/p-2.png" alt="p-2"/>
                </a>
                <div class="card-body py-2">
                  <h3 class="product-title fs-sm text-truncate mb-2">
                    <a href="javascript:;">Amethyst</a>
                    <span class="product-meta">(1490mg)</span>
                  </h3>
                  <h3 class="product-title fs-sm text-truncate">
                    <span class="product-meta">SKU: 40311347889</span>
                  </h3>
                  <div class="textRed font18">
                    Rs. 2700
                  </div>
                </div>
                <div class="product-floating-btn">
                  <button class="btn btn-primary btn-shadow btn-sm" type="button">+<i class="ci-cart fs-base ms-1"></i></button>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
              <div class="card product-card card-static pb-3">
                <button class="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title="Add to wishlist">
                  <i class="ci-heart"></i>
                </button>
                <a class="card-img-top d-block overflow-hidden pt-4 pb-2" href="javascript:;">
                  <img class="d-block mx-auto" src="img/home/gemstone/p-4.png" alt="p-4"/>
                </a>
                <div class="card-body py-2">
                  <h3 class="product-title fs-sm text-truncate mb-2">
                    <a href="javascript:;l">Emerlad </a>
                    <span class="product-meta">(1490mg)</span>
                  </h3>
                  <h3 class="product-title fs-sm text-truncate">
                    <span class="product-meta">SKU: 40311347889</span>
                  </h3>
                  <div class="textRed font18">
                    Rs. 3500
                  </div>
                </div>
                <div class="product-floating-btn">
                  <button class="btn btn-primary btn-shadow btn-sm" type="button">+<i class="ci-cart fs-base ms-1"></i></button>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
              <div class="card product-card card-static pb-3">
                <button class="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title="Add to wishlist">
                  <i class="ci-heart"></i>
                </button>
                <a class="card-img-top d-block overflow-hidden pt-4 pb-2" href="javascript:;">
                  <img class="d-block mx-auto" src="img/home/gemstone/p-2.png" alt="p-2"/>
                </a>
                <div class="card-body py-2">
                  <h3 class="product-title fs-sm text-truncate mb-2">
                    <a href="javascript:;">Amethyst</a>
                    <span class="product-meta">(1490mg)</span>
                  </h3>
                  <h3 class="product-title fs-sm text-truncate">
                    <span class="product-meta">SKU: 40311347889</span>
                  </h3>
                  <div class="textRed font18">
                    Rs. 2700
                  </div>
                </div>
                <div class="product-floating-btn">
                  <button class="btn btn-primary btn-shadow btn-sm" type="button">+<i class="ci-cart fs-base ms-1"></i></button>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
              <div class="card product-card card-static pb-3">
                <button class="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title="Add to wishlist">
                  <i class="ci-heart"></i>
                </button>
                <a class="card-img-top d-block overflow-hidden pt-4 pb-2" href="javascript:;">
                  <img class="d-block mx-auto" src="img/home/gemstone/p-1.png" alt="p-1"/>
                </a>
                <div class="card-body py-2">
                  <h3 class="product-title fs-sm text-truncate mb-2">
                    <a href="javascript:;">Amethyst</a>
                    <span class="product-meta">(1490mg)</span>
                  </h3>
                  <h3 class="product-title fs-sm text-truncate">
                    <span class="product-meta">SKU: 40311347889</span>
                  </h3>
                  <div class="textRed font18">
                    Rs. 1500
                  </div>
                </div>
                <div class="product-floating-btn">
                  <button class="btn btn-primary btn-shadow btn-sm" type="button">+<i class="ci-cart fs-base ms-1"></i></button>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
              <div class="card product-card card-static pb-3">
                <button class="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title="Add to wishlist">
                  <i class="ci-heart"></i>
                </button>
                <a class="card-img-top d-block overflow-hidden pt-4 pb-2" href="javascript:;">
                  <img class="d-block mx-auto" src="img/home/gemstone/p-3.png" alt="p-3"/>
                </a>
                <div class="card-body py-2">
                  <h3 class="product-title fs-sm text-truncate mb-2">
                    <a href="javascript:;">Citrine</a>
                    <span class="product-meta">(1490mg)</span>
                  </h3>
                  <h3 class="product-title fs-sm text-truncate">
                    <span class="product-meta">SKU: 40311347889</span>
                  </h3>
                  <div class="textRed font18">
                    Rs. 2500
                  </div>
                </div>
                <div class="product-floating-btn">
                  <button class="btn btn-primary btn-shadow btn-sm" type="button">+<i class="ci-cart fs-base ms-1"></i></button>
                </div>
              </div>
            </div>
          </div>

          <div class="row py-sm-2">
            <div class="col-lg-6 col-sm-12 mb-4">
              <div class="d-block d-sm-flex justify-content-between align-items-center radius20 bgGray">
                <div class="pt-5 py-sm-5 px-4 ps-md-5 pe-md-0 text-center text-sm-start">
                  <h2 class="textRed font16">9 Gem</h2>
                  <p class="pb-2 font30 textDarkBlue">Just Lunched Desk The Hals</p>
                  <a class="btn btn-outline-primary btn-shadow text-uppercase" href="#">Shop Now</a>
                </div>
                <img class="d-block w-100 mx-auto radius20" src="img/home/gemstone/gem-2.jpg" alt=""/>
              </div>
            </div>
            <div class="col-lg-6 col-sm-12 mb-4">
              <div class="d-block d-sm-flex justify-content-between align-items-center radius20 bgLightPink">
                <div class="pt-5 py-sm-5 px-4 ps-md-5 pe-md-0 text-center text-sm-start">
                  <h2 class="textRed font16">9 Gem</h2>
                  <p class="pb-2 font30 textDarkBlue">Hottest Selling   Gemstone</p>
                  <a class="btn btn-outline-primary btn-shadow text-uppercase" href="#">Shop Now</a>
                </div>
                <img class="d-block w-100 mx-auto radius20" src="img/home/gemstone/gem-4.jpg" alt=""/>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
              <div class="card product-card card-static pb-3">
                <button class="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title="Add to wishlist">
                  <i class="ci-heart"></i>
                </button>
                <a class="card-img-top d-block overflow-hidden pt-4 pb-2" href="javascript:;">
                  <img class="d-block mx-auto" src="img/home/gemstone/p-1.png" alt="p-1"/>
                </a>
                <div class="card-body py-2">
                  <h3 class="product-title fs-sm text-truncate mb-2">
                    <a href="javascript:;">Amethyst</a>
                    <span class="product-meta">(1490mg)</span>
                  </h3>
                  <h3 class="product-title fs-sm text-truncate">
                    <span class="product-meta">SKU: 40311347889</span>
                  </h3>
                  <div class="textRed font18">
                    Rs. 1500
                  </div>
                </div>
                <div class="product-floating-btn">
                  <button class="btn btn-primary btn-shadow btn-sm" type="button">+<i class="ci-cart fs-base ms-1"></i></button>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
              <div class="card product-card card-static pb-3">
                <button class="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title="Add to wishlist">
                  <i class="ci-heart"></i>
                </button>
                <a class="card-img-top d-block overflow-hidden pt-4 pb-2" href="javascript:;">
                  <img class="d-block mx-auto" src="img/home/gemstone/p-3.png" alt="p-3"/>
                </a>
                <div class="card-body py-2">
                  <h3 class="product-title fs-sm text-truncate mb-2">
                    <a href="javascript:;">Citrine</a>
                    <span class="product-meta">(1490mg)</span>
                  </h3>
                  <h3 class="product-title fs-sm text-truncate">
                    <span class="product-meta">SKU: 40311347889</span>
                  </h3>
                  <div class="textRed font18">
                    Rs. 2500
                  </div>
                </div>
                <div class="product-floating-btn">
                  <button class="btn btn-primary btn-shadow btn-sm" type="button">+<i class="ci-cart fs-base ms-1"></i></button>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
              <div class="card product-card card-static pb-3">
                <button class="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title="Add to wishlist">
                  <i class="ci-heart"></i>
                </button>
                <a class="card-img-top d-block overflow-hidden pt-4 pb-2" href="javascript:;">
                  <img class="d-block mx-auto" src="img/home/gemstone/p-4.png" alt="p-4"/>
                </a>
                <div class="card-body py-2">
                  <h3 class="product-title fs-sm text-truncate mb-2">
                    <a href="javascript:;l">Emerlad </a>
                    <span class="product-meta">(1490mg)</span>
                  </h3>
                  <h3 class="product-title fs-sm text-truncate">
                    <span class="product-meta">SKU: 40311347889</span>
                  </h3>
                  <div class="textRed font18">
                    Rs. 3500
                  </div>
                </div>
                <div class="product-floating-btn">
                  <button class="btn btn-primary btn-shadow btn-sm" type="button">+<i class="ci-cart fs-base ms-1"></i></button>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
              <div class="card product-card card-static pb-3">
                <button class="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title="Add to wishlist">
                  <i class="ci-heart"></i>
                </button>
                <a class="card-img-top d-block overflow-hidden pt-4 pb-2" href="javascript:;">
                  <img class="d-block mx-auto" src="img/home/gemstone/p-2.png" alt="p-2"/>
                </a>
                <div class="card-body py-2">
                  <h3 class="product-title fs-sm text-truncate mb-2">
                    <a href="javascript:;">Amethyst</a>
                    <span class="product-meta">(1490mg)</span>
                  </h3>
                  <h3 class="product-title fs-sm text-truncate">
                    <span class="product-meta">SKU: 40311347889</span>
                  </h3>
                  <div class="textRed font18">
                    Rs. 2700
                  </div>
                </div>
                <div class="product-floating-btn">
                  <button class="btn btn-primary btn-shadow btn-sm" type="button">+<i class="ci-cart fs-base ms-1"></i></button>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
              <div class="card product-card card-static pb-3">
                <button class="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title="Add to wishlist">
                  <i class="ci-heart"></i>
                </button>
                <a class="card-img-top d-block overflow-hidden pt-4 pb-2" href="javascript:;">
                  <img class="d-block mx-auto" src="img/home/gemstone/p-4.png" alt="p-4"/>
                </a>
                <div class="card-body py-2">
                  <h3 class="product-title fs-sm text-truncate mb-2">
                    <a href="javascript:;l">Emerlad </a>
                    <span class="product-meta">(1490mg)</span>
                  </h3>
                  <h3 class="product-title fs-sm text-truncate">
                    <span class="product-meta">SKU: 40311347889</span>
                  </h3>
                  <div class="textRed font18">
                    Rs. 3500
                  </div>
                </div>
                <div class="product-floating-btn">
                  <button class="btn btn-primary btn-shadow btn-sm" type="button">+<i class="ci-cart fs-base ms-1"></i></button>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
              <div class="card product-card card-static pb-3">
                <button class="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title="Add to wishlist">
                  <i class="ci-heart"></i>
                </button>
                <a class="card-img-top d-block overflow-hidden pt-4 pb-2" href="javascript:;">
                  <img class="d-block mx-auto" src="img/home/gemstone/p-2.png" alt="p-2"/>
                </a>
                <div class="card-body py-2">
                  <h3 class="product-title fs-sm text-truncate mb-2">
                    <a href="javascript:;">Amethyst</a>
                    <span class="product-meta">(1490mg)</span>
                  </h3>
                  <h3 class="product-title fs-sm text-truncate">
                    <span class="product-meta">SKU: 40311347889</span>
                  </h3>
                  <div class="textRed font18">
                    Rs. 2700
                  </div>
                </div>
                <div class="product-floating-btn">
                  <button class="btn btn-primary btn-shadow btn-sm" type="button">+<i class="ci-cart fs-base ms-1"></i></button>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
              <div class="card product-card card-static pb-3">
                <button class="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title="Add to wishlist">
                  <i class="ci-heart"></i>
                </button>
                <a class="card-img-top d-block overflow-hidden pt-4 pb-2" href="javascript:;">
                  <img class="d-block mx-auto" src="img/home/gemstone/p-1.png" alt="p-1"/>
                </a>
                <div class="card-body py-2">
                  <h3 class="product-title fs-sm text-truncate mb-2">
                    <a href="javascript:;">Amethyst</a>
                    <span class="product-meta">(1490mg)</span>
                  </h3>
                  <h3 class="product-title fs-sm text-truncate">
                    <span class="product-meta">SKU: 40311347889</span>
                  </h3>
                  <div class="textRed font18">
                    Rs. 1500
                  </div>
                </div>
                <div class="product-floating-btn">
                  <button class="btn btn-primary btn-shadow btn-sm" type="button">+<i class="ci-cart fs-base ms-1"></i></button>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
              <div class="card product-card card-static pb-3">
                <button class="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title="Add to wishlist">
                  <i class="ci-heart"></i>
                </button>
                <a class="card-img-top d-block overflow-hidden pt-4 pb-2" href="javascript:;">
                  <img class="d-block mx-auto" src="img/home/gemstone/p-3.png" alt="p-3"/>
                </a>
                <div class="card-body py-2">
                  <h3 class="product-title fs-sm text-truncate mb-2">
                    <a href="javascript:;">Citrine</a>
                    <span class="product-meta">(1490mg)</span>
                  </h3>
                  <h3 class="product-title fs-sm text-truncate">
                    <span class="product-meta">SKU: 40311347889</span>
                  </h3>
                  <div class="textRed font18">
                    Rs. 2500
                  </div>
                </div>
                <div class="product-floating-btn">
                  <button class="btn btn-primary btn-shadow btn-sm" type="button">+<i class="ci-cart fs-base ms-1"></i></button>
                </div>
              </div>
            </div>
          </div>

          <hr class="my-3"/>

          <nav class="d-flex justify-content-between pt-2" aria-label="Page navigation">
            <ul class="pagination">
              <li class="page-item"><a class="page-link" href="#"><i class="ci-arrow-left me-2"></i>Prev</a></li>
            </ul>
            <ul class="pagination">
              <li class="page-item d-sm-none"><span class="page-link page-link-static">1 / 5</span></li>
              <li class="page-item active d-none d-sm-block" aria-current="page"><span class="page-link">1<span class="visually-hidden">(current)</span></span></li>
              <li class="page-item d-none d-sm-block"><a class="page-link" href="#">2</a></li>
              <li class="page-item d-none d-sm-block"><a class="page-link" href="#">3</a></li>
              <li class="page-item d-none d-sm-block"><a class="page-link" href="#">4</a></li>
              <li class="page-item d-none d-sm-block"><a class="page-link" href="#">5</a></li>
            </ul>
            <ul class="pagination">
              <li class="page-item"><a class="page-link" href="#" aria-label="Next">Next<i class="ci-arrow-right ms-2"></i></a></li>
            </ul>
          </nav>

        </div>
      </section>


      <section class="pt-5 pb-4 pb-lg-5">
        <div class="container pt-lg-3">
          <div class="row pt-lg-2 text-left text-md-center">
            <div class="col-lg-3 col-sm-6 mb-grid-gutter">
              <div class="d-inline-flex align-items-center text-start">
                <img src="img/home/gemstone/icon-1.png" width="48" alt="Quality Guarantee"/>
                <div class="ps-3">
                  <h6 class="text-light fontWeight700 font18 mb-1">Fast Shipping</h6>
                  <p class="text-muted mb-0">Dependable delivery <br/> service  for rapid shipping.</p>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6 mb-grid-gutter">
              <div class="d-inline-flex align-items-center text-start">
                <img src="img/home/gemstone/icon-2.png" width="48" alt="Customer Support"/>
                <div class="ps-3">
                  <h6 class="text-light fontWeight700 font18 mb-1">Flexible Payment</h6>
                  <p class="text-muted mb-0">Pay with Multiple Credit <br/> Cards.</p>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6 mb-grid-gutter">
              <div class="d-inline-flex align-items-center text-start">
                <img src="img/home/gemstone/icon-3.png" width="48" alt="Free Updates"/>
                <div class="ps-3">
                  <h6 class="text-light fontWeight700 font18 mb-1">Money Guarantee</h6>
                  <p class="text-muted mb-0">Within 7 days for an <br/> exchange.</p>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6 mb-grid-gutter">
              <div class="d-inline-flex align-items-center text-start">
                <img src="img/home/gemstone/icon-4.png" width="48" alt="Secure Payments"/>
                <div class="ps-3">
                  <h6 class="text-light fontWeight700 font18 mb-1">Online Support</h6>
                  <p class="text-muted mb-0">We are available for <br/> 24x7</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
   <FooterA/>
   </>
  )
}

export default Product
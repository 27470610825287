import React from 'react'
import Navbar from '../Components/Navbar/Navbar';
import NavbarTwo from '../Components/NavbarTwo/NavbarTwo';
import FooterA from '../Components/FooterA/FooterA';
const AccountOrders = () => {
  return (
  <>
  <Navbar/>
  <NavbarTwo/>
   <section class="page-title-overlap bgBlue pt-4 pb-5">
        <div class="container pt-2 pb-3 pt-lg-3 pb-lg-4">
          <div class="d-lg-flex justify-content-between pb-5">
              <div class="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
                    <li class="breadcrumb-item">
                      <a class="text-nowrap" href="/"><i class="ci-home"></i>Home</a>
                    </li>
                    <li class="breadcrumb-item text-nowrap"><a href="javascript:;">Account</a></li>
                    <li class="breadcrumb-item text-nowrap active" aria-current="page">My Orders</li>
                  </ol>
                </nav>
              </div>
              <div class="order-lg-1 pe-lg-4 text-center text-lg-start">
                <h1 class="h3 text-white mb-0">My Orders</h1>
              </div>
          </div>
        </div>
      </section>


      <div class="container pb-5 mb-2 mb-md-4">
        <div class="row">
         
          <aside class="col-lg-4 pt-4 pt-lg-0 pe-xl-5">
            <div class="bgwhite radius8 shadow-lg pt-1 mb-5 mb-lg-0">
              <div class="d-md-flex justify-content-between align-items-center text-center text-md-start p-4">
                <div class="d-md-flex align-items-center">
                  <div class="img-thumbnail rounded-circle position-relative flex-shrink-0 mx-auto mb-2 mx-md-0 mb-md-0" style={{width: '6.375rem'}}>
                    <img class="rounded-circle" src="img/home/gemstone/avatar.jpg" alt="Hardeep Singh"/>
                  </div>
                  <div class="ps-md-3">
                    <h3 class="fs-base mb-0">Hardeep Singh</h3>
                    <span class="text-primary fs-sm">prince.villkhu@gmail.com</span>
                  </div>
                </div>
                <a class="btn btn-primary d-lg-none mb-2 mt-3 mt-md-0" href="#account-menu" data-bs-toggle="collapse" aria-expanded="false">
                  <i class="ci-menu me-2"></i>Account menu
                </a>
              </div>

              <div class="d-lg-block collapse" id="account-menu">
                <div class="bg-secondary px-4 py-3">
                  <h3 class="fs-sm mb-0 text-muted">Dashboard</h3>
                </div>
                <ul class="list-unstyled mb-0">
                  <li class="border-bottom mb-0">
                    <a class="nav-link-style d-flex align-items-center px-4 py-3 active" href="/account-orders"><i class="ci-bag opacity-60 me-2"></i>Orders
                      <span class="fs-sm text-muted ms-auto">1</span>
                    </a>
                  </li>
                  <li class="border-bottom mb-0">
                    <a class="nav-link-style d-flex align-items-center px-4 py-3" href="/account-wishlist"><i class="ci-heart opacity-60 me-2"></i>Wishlist
                      <span class="fs-sm text-muted ms-auto">3</span>
                    </a>
                  </li>
                </ul>
                <div class="bg-secondary px-4 py-3">
                  <h3 class="fs-sm mb-0 text-muted">Account settings</h3>
                </div>
                <ul class="list-unstyled mb-0">
                  <li class="border-bottom mb-0">
                    <a class="nav-link-style d-flex align-items-center px-4 py-3" href="/account-profile"><i class="ci-user opacity-60 me-2"></i>Profile info</a>
                  </li>
                  <li class="border-bottom mb-0">
                    <a class="nav-link-style d-flex align-items-center px-4 py-3" href="/account-address"><i class="ci-location opacity-60 me-2"></i>Addresses</a>
                  </li>
                  <li class="mb-0">
                    <a class="nav-link-style d-flex align-items-center px-4 py-3" href="/account-payment"><i class="ci-card opacity-60 me-2"></i>Payment methods</a>
                    </li>
                  <li class="d-lg-none border-top mb-0">
                    <a class="nav-link-style d-flex align-items-center px-4 py-3" href="/login"><i class="ci-sign-out opacity-60 me-2"></i>Sign out</a>
                  </li>
                </ul>
              </div>
            </div>
          </aside>
       
          <section class="col-lg-8">
           
            <div class="d-flex justify-content-between align-items-center pt-lg-2 pb-4 pb-lg-5 mb-lg-3">
              <div class="d-flex align-items-center">
                <label class="d-none d-lg-block fs-sm text-white text-nowrap opacity-75 me-2" for="order-sort">Sort orders:</label>
                <label class="d-lg-none fs-sm text-nowrap opacity-75 me-2" for="order-sort">Sort orders:</label>
                <select class="form-select" id="order-sort">
                  <option>All</option>
                  <option>Delivered</option>
                  <option>In Progress</option>
                  <option>Delayed</option>
                  <option>Canceled</option>
                </select>
              </div><a class="btn btn-primary btn-sm d-none d-lg-inline-block" href="/login"><i class="ci-sign-out me-2"></i>Sign out</a>
            </div>
           
            <div class="table-responsive fs-md mb-4">
              <table class="table table-hover mb-0">
                <thead>
                  <tr>
                    <th>Order #</th>
                    <th>Date Purchased</th>
                    <th>Status</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="py-3"><a class="nav-link-style fw-medium fs-sm" href="/order-view">34VB5540K83</a></td>
                    <td class="py-3">May 21, 2019</td>
                    <td class="py-3"><span class="badge bg-info m-0">In Progress</span></td>
                    <td class="py-3">$358.75</td>
                  </tr>
                  <tr>
                    <td class="py-3"><a class="nav-link-style fw-medium fs-sm" href="/order-view">78A643CD409</a></td>
                    <td class="py-3">December 09, 2018</td>
                    <td class="py-3"><span class="badge bg-danger m-0">Canceled</span></td>
                    <td class="py-3"><span>$760.50</span></td>
                  </tr>
                  <tr>
                    <td class="py-3"><a class="nav-link-style fw-medium fs-sm" href="/order-view">112P45A90V2</a></td>
                    <td class="py-3">October 15, 2018</td>
                    <td class="py-3"><span class="badge bg-warning m-0">Delayed</span></td>
                    <td class="py-3">$1,264.00</td>
                  </tr>
                  <tr>
                    <td class="py-3"><a class="nav-link-style fw-medium fs-sm" href="/order-view">28BA67U0981</a></td>
                    <td class="py-3">July 19, 2018</td>
                    <td class="py-3"><span class="badge bg-success m-0">Delivered</span></td>
                    <td class="py-3">$198.35</td>
                  </tr>
                  <tr>
                    <td class="py-3"><a class="nav-link-style fw-medium fs-sm" href="/order-view">502TR872W2</a></td>
                    <td class="py-3">April 04, 2018</td>
                    <td class="py-3"><span class="badge bg-success m-0">Delivered</span></td>
                    <td class="py-3">$2,133.90</td>
                  </tr>
                  <tr>
                    <td class="py-3"><a class="nav-link-style fw-medium fs-sm" href="/order-view">47H76G09F33</a></td>
                    <td class="py-3">March 30, 2018</td>
                    <td class="py-3"><span class="badge bg-success m-0">Delivered</span></td>
                    <td class="py-3">$86.40</td>
                  </tr>
                </tbody>
              </table>
            </div>
         
            <nav class="d-flex justify-content-between pt-2" aria-label="Page navigation">
              <ul class="pagination">
                <li class="page-item"><a class="page-link" href="#"><i class="ci-arrow-left me-2"></i>Prev</a></li>
              </ul>
              <ul class="pagination">
                <li class="page-item d-sm-none"><span class="page-link page-link-static">1 / 5</span></li>
                <li class="page-item active d-none d-sm-block" aria-current="page"><span class="page-link">1<span class="visually-hidden">(current)</span></span></li>
                <li class="page-item d-none d-sm-block"><a class="page-link" href="#">2</a></li>
                <li class="page-item d-none d-sm-block"><a class="page-link" href="#">3</a></li>
                <li class="page-item d-none d-sm-block"><a class="page-link" href="#">4</a></li>
                <li class="page-item d-none d-sm-block"><a class="page-link" href="#">5</a></li>
              </ul>
              <ul class="pagination">
                <li class="page-item"><a class="page-link" href="#" aria-label="Next">Next<i class="ci-arrow-right ms-2"></i></a></li>
              </ul>
            </nav>
          </section>
        </div>
      </div>
      <FooterA/>
  </>
  )
}

export default AccountOrders
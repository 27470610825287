import React from 'react'
import Navbar from '../Components/Navbar/Navbar';
import NavbarTwo from '../Components/NavbarTwo/NavbarTwo';
import FooterA from '../Components/FooterA/FooterA';
const Blog = () => {
  return (
    <>
    <Navbar/>
    <NavbarTwo/>
    <section class="bgBlue pt-4 pb-4">
        <div class="container pt-2 pb-3 pt-lg-3 pb-lg-4">
          <div class="d-lg-flex justify-content-between">
              <div class="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
                    <li class="breadcrumb-item">
                      <a class="text-nowrap" href="/"><i class="ci-home"></i>Home</a>
                    </li>
                    <li class="breadcrumb-item text-nowrap active" aria-current="page">Blog</li>
                  </ol>
                </nav>
              </div>
              <div class="order-lg-1 pe-lg-4 text-center text-lg-start">
                <h1 class="h3 text-white mb-0">Blog</h1>
              </div>
          </div>
        </div>
      </section>


      <section class="py-5">
        <div class="container">
            <div class="row mt-md-2">

              <div class="col-lg-8">
                <div class="masonry-grid" data-columns="2">

                  <article class="masonry-grid-item">
                    <div class="card">
                      <a class="blog-entry-thumb" href="/blog-details">
                      <img class="card-img-top" src="img/home/blog/blog-1.jpg" alt="blog-1"/>
                      </a>
                      <div class="card-body">
                        <h2 class="h6 blog-entry-title">
                          <a href="/blog-details">Most Expensive Gemstones In The World</a>
                        </h2>
                        <p class="fs-sm">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua enim ad minim...</p>
                      </div>
                      <div class="card-footer d-flex align-items-center fs-xs"><a class="blog-entry-meta-link" href="#">
                        <div class="blog-entry-author-ava">
                          <img class="card-img-top" src="img/home/blog/03.jpg" alt="Hardeep Singh"/>
                        </div>Hardeep Singh</a>
                        <div class="ms-auto text-nowrap">
                          <a class="blog-entry-meta-link text-nowrap" href="#">May 29</a>
                          <span class="blog-entry-meta-divider mx-2"></span>
                          <a class="blog-entry-meta-link text-nowrap" href="javascript:;">
                            <i class="ci-message"></i>03
                          </a>
                        </div>
                      </div>
                    </div>
                  </article>
                  <article class="masonry-grid-item">
                    <div class="card">
                      <a class="blog-entry-thumb" href="/blog-details">
                      <img class="card-img-top" src="img/home/blog/blog-1.jpg" alt="blog-1"/>
                      </a>
                      <div class="card-body">
                        <h2 class="h6 blog-entry-title">
                          <a href="/blog-details">Most Expensive Gemstones In The World</a>
                        </h2>
                        <p class="fs-sm">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua enim ad minim...</p>
                      </div>
                      <div class="card-footer d-flex align-items-center fs-xs"><a class="blog-entry-meta-link" href="#">
                        <div class="blog-entry-author-ava">
                          <img class="card-img-top" src="img/home/blog/03.jpg" alt="Hardeep Singh"/>
                        </div>Hardeep Singh</a>
                        <div class="ms-auto text-nowrap">
                          <a class="blog-entry-meta-link text-nowrap" href="#">May 29</a>
                          <span class="blog-entry-meta-divider mx-2"></span>
                          <a class="blog-entry-meta-link text-nowrap" href="javascript:;">
                            <i class="ci-message"></i>03
                          </a>
                        </div>
                      </div>
                    </div>
                  </article>
                  <article class="masonry-grid-item">
                    <div class="card">
                      <a class="blog-entry-thumb" href="/blog-details">
                      <img class="card-img-top" src="img/home/blog/blog-1.jpg" alt="blog-1"/>
                      </a>
                      <div class="card-body">
                        <h2 class="h6 blog-entry-title">
                          <a href="/blog-details">Most Expensive Gemstones In The World</a>
                        </h2>
                        <p class="fs-sm">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua enim ad minim...</p>
                      </div>
                      <div class="card-footer d-flex align-items-center fs-xs"><a class="blog-entry-meta-link" href="#">
                        <div class="blog-entry-author-ava">
                          <img class="card-img-top" src="img/home/blog/03.jpg" alt="Hardeep Singh"/>
                        </div>Hardeep Singh</a>
                        <div class="ms-auto text-nowrap">
                          <a class="blog-entry-meta-link text-nowrap" href="#">May 29</a>
                          <span class="blog-entry-meta-divider mx-2"></span>
                          <a class="blog-entry-meta-link text-nowrap" href="javascript:;">
                            <i class="ci-message"></i>03
                          </a>
                        </div>
                      </div>
                    </div>
                  </article>
                  <article class="masonry-grid-item">
                    <div class="card">
                      <a class="blog-entry-thumb" href="/blog-details">
                      <img class="card-img-top" src="img/home/blog/blog-1.jpg" alt="blog-1"/>
                      </a>
                      <div class="card-body">
                        <h2 class="h6 blog-entry-title">
                          <a href="/blog-details">Most Expensive Gemstones In The World</a>
                        </h2>
                        <p class="fs-sm">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua enim ad minim...</p>
                      </div>
                      <div class="card-footer d-flex align-items-center fs-xs"><a class="blog-entry-meta-link" href="#">
                        <div class="blog-entry-author-ava">
                          <img class="card-img-top" src="img/home/blog/03.jpg" alt="Hardeep Singh"/>
                        </div>Hardeep Singh</a>
                        <div class="ms-auto text-nowrap">
                          <a class="blog-entry-meta-link text-nowrap" href="#">May 29</a>
                          <span class="blog-entry-meta-divider mx-2"></span>
                          <a class="blog-entry-meta-link text-nowrap" href="javascript:;">
                            <i class="ci-message"></i>03
                          </a>
                        </div>
                      </div>
                    </div>
                  </article>

                </div>
                <hr class="mb-4"/>
                <nav class="d-flex justify-content-between pt-2" aria-label="Page navigation">
                  <ul class="pagination">
                    <li class="page-item"><a class="page-link" href="#"><i class="ci-arrow-left me-2"></i>Prev</a></li>
                  </ul>
                  <ul class="pagination">
                    <li class="page-item d-sm-none"><span class="page-link page-link-static">1 / 5</span></li>
                    <li class="page-item active d-none d-sm-block" aria-current="page"><span class="page-link">1<span class="visually-hidden">(current)</span></span></li>
                    <li class="page-item d-none d-sm-block"><a class="page-link" href="#">2</a></li>
                    <li class="page-item d-none d-sm-block"><a class="page-link" href="#">3</a></li>
                    <li class="page-item d-none d-sm-block"><a class="page-link" href="#">4</a></li>
                    <li class="page-item d-none d-sm-block"><a class="page-link" href="#">5</a></li>
                  </ul>
                  <ul class="pagination">
                    <li class="page-item"><a class="page-link" href="#" aria-label="Next">Next<i class="ci-arrow-right ms-2"></i></a></li>
                  </ul>
                </nav>
              </div>


              <aside class="col-lg-4">
                <div class="offcanvas offcanvas-collapse offcanvas-end border-start ms-lg-auto" id="blog-sidebar" style={{maxWidth: '22rem'}}>
                  <div class="offcanvas-header align-items-center shadow-sm">
                    <h2 class="h5 mb-0">Sidebar</h2>
                    <button class="btn-close ms-auto" type="button" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                  </div>
                  <div class="offcanvas-body py-grid-gutter py-lg-1 px-lg-4" data-simplebar data-simplebar-auto-hide="true">
                    <div class="widget widget-links mb-grid-gutter pb-grid-gutter border-bottom mx-lg-2">
                      <h3 class="widget-title">Blog categories</h3>
                      <ul class="widget-list">
                        <li class="widget-list-item">
                          <a class="widget-list-link d-flex justify-content-between align-items-center" href="#">
                            <span>Category 1</span>
                            <span class="fs-xs text-muted ms-3">18</span>
                          </a>
                        </li>
                        <li class="widget-list-item">
                          <a class="widget-list-link d-flex justify-content-between align-items-center" href="#">
                            <span>Category 2</span>
                            <span class="fs-xs text-muted ms-3">25</span>
                          </a>
                        </li>
                        <li class="widget-list-item">
                          <a class="widget-list-link d-flex justify-content-between align-items-center" href="#">
                            <span>Category 3</span>
                            <span class="fs-xs text-muted ms-3">13</span>
                          </a>
                        </li>
                        <li class="widget-list-item">
                          <a class="widget-list-link d-flex justify-content-between align-items-center" href="#">
                            <span>Category 4</span>
                            <span class="fs-xs text-muted ms-3">7</span>
                          </a>
                        </li>
                        <li class="widget-list-item">
                          <a class="widget-list-link d-flex justify-content-between align-items-center" href="#">
                            <span>Category 5</span>
                            <span class="fs-xs text-muted ms-3">34</span>
                          </a>
                        </li>
                        <li class="widget-list-item">
                          <a class="widget-list-link d-flex justify-content-between align-items-center" href="#">
                            <span>Category 6</span>
                            <span class="fs-xs text-muted ms-3">6</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="widget pb-grid-gutter mx-lg-2">
                      <h3 class="widget-title">Popular tags</h3>
                      <a class="btn-tag me-2 mb-2" href="#">#Genstone</a>
                      <a class="btn-tag me-2 mb-2" href="#">#gems</a>
                      <a class="btn-tag me-2 mb-2" href="#">#Genstone</a>
                      <a class="btn-tag me-2 mb-2" href="#">#gems</a>
                      <a class="btn-tag me-2 mb-2" href="#">#Genstone</a>
                      <a class="btn-tag me-2 mb-2" href="#">#gems</a>
                      <a class="btn-tag me-2 mb-2" href="#">#Genstone</a>
                      <a class="btn-tag me-2 mb-2" href="#">#gems</a>
                    </div>
                  </div>
                </div>
              </aside>
            </div>
           </div>
      </section>
      <FooterA/>
    </>
  )
}

export default Blog
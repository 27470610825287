import React from 'react'

const ForgotPasswordOTP = () => {
  return (
    <>
     <section class="d-flex justify-content-center align-items-center h-100">
      <div class="lgoinSignMain">
        <div class="d-flex justify-content-center">
          <a href="/">
            <img src="img/logo-dark.png" width="100" alt="9 Gem"/>
          </a>
        </div>
        <div class="card my-3">
          <div class="card-body">
            <h5><i class="ci-unlocked me-2 mt-n1"></i>Authentication required</h5>
            <p class="mb-3 font14"> <span>8284040490</span> <a href="/forgot-password" class="text-danger">Change</a></p>
            <p class="text-muted">We have sent a One Time Password (OTP) to the mobile number above. Please enter it to complete verification.</p>
            <form>
              <div class="mb-3">
                <label class="form-label font16">Enter OTP</label>
                <input class="form-control" type="text" placeholder=""/>
              </div>
              <a href="/login-password" class="btn btn-primary btn-shadow d-block w-100" type="submit">Sign In</a>
            </form>
            <div class="text-center mt-4">
              <p class="mb-3 font14"> <a href="#"><i class="ci-reload me-2 mt-n1"></i>Resend OTP</a></p>
              <p class="mb-3 font14"> <a href="#"><i class="ci-phone me-2 mt-n1"></i>Voice OTP</a></p>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default ForgotPasswordOTP
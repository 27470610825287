import React from 'react'

const ForgotPssword = () => {
  return (
   <>
    <section class="d-flex justify-content-center align-items-center h-100">
      <div class="lgoinSignMain">
        <div class="d-flex justify-content-center">
          <a href="/">
            <img src="img/logo-dark.png" width="100" alt="9 Gem"/>
          </a>
        </div>
        <div class="card my-3">
          <div class="card-body">
            <h5><i class="ci-unlocked me-2 mt-n1"></i>Password assistance</h5>
            <p class="text-muted">Enter the email address or mobile phone number associated with your Gemlab account.</p>
            <form>
              <div class="mb-3">
                <label class="form-label font16">Email or Mobile phone number</label>
                <input class="form-control" type="email" placeholder=""/>
              </div>
              <a href="/forgot-password-otp" class="btn btn-primary btn-shadow d-block w-100" type="submit">Continue</a>
            </form>
          </div>
        </div>
      </div>
    </section>
   </>
  )
}

export default ForgotPssword
import React from 'react';
import Navbar from '../Components/Navbar/Navbar';
import NavbarTwo from '../Components/NavbarTwo/NavbarTwo';
import FooterA from '../Components/FooterA/FooterA';

const ShopCart = () => {
  return (
    <>
    <Navbar/>
    <NavbarTwo/>
     <section class="page-title-overlap bgBlue pt-4 pb-5">
        <div class="container pt-2 pb-3 pt-lg-3 pb-lg-4">
          <div class="d-lg-flex justify-content-between pb-3">
              <div class="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
                    <li class="breadcrumb-item">
                      <a class="text-nowrap" href="/"><i class="ci-home"></i>Home</a>
                    </li>
                    <li class="breadcrumb-item text-nowrap"><a href="/products">Products</a></li>
                    <li class="breadcrumb-item text-nowrap active" aria-current="page">Cart</li>
                  </ol>
                </nav>
              </div>
              <div class="order-lg-1 pe-lg-4 text-center text-lg-start">
                <h1 class="h3 text-white mb-0">Your cart</h1>
              </div>
          </div>
        </div>
      </section>


      <section class="pb-5 mb-2 mb-md-4 pt-4">
        <div class="container">
          <div class="row">
           
            <section class="col-lg-8">
              <div class="d-flex justify-content-between align-items-center pt-1 pb-4 pb-sm-5 mt-1">
                <h2 class="h6 text-white mb-0">Products</h2>
                <a class="btn btn-outline-primary btn-sm ps-2 text-uppercase" href="/products">
                  <i class="ci-arrow-left me-2"></i>Continue Shopping
                </a>
              </div>
            
              <div class="d-sm-flex justify-content-between align-items-center my-2 pb-3 border-bottom">
                <div class="d-block d-sm-flex align-items-center text-center text-sm-start">
                  <a class="d-inline-block flex-shrink-0 mx-auto me-sm-4" href="javascript:;">
                    <img src="img/home/shop/cart/01.jpg" class="radius8" width="160" alt="Product"/>
                  </a>
                  <div class="pt-2">
                    <h3 class="product-title fs-base mb-2">
                      <a href="javascript:;">Amethyst (1490mg)</a> 
                    </h3>
                    <div class="fs-sm"><span class="text-muted me-2">SKU:</span>40311347889</div>
                    <div class="fs-sm"><span class="text-muted me-2">Ratti:</span>12+</div>
                    <div class="fs-lg text-primary pt-2">Rs. 1500</div>
                  </div>
                </div>
                <div class="pt-2 pt-sm-0 ps-sm-3 mx-auto mx-sm-0 text-center text-sm-start" style={{maxWidth: '9rem'}}>
                  <label class="form-label" for="quantity1">Quantity</label>
                  <input class="form-control" type="number" id="quantity1" min="1" value="1"/>
                  <button class="px-0 py-3 border-0 bgwhite d-block w-100 text-danger" type="button">
                    <i class="ci-close-circle me-2"></i>
                    <span class="fs-sm">Remove</span>
                  </button>
                </div>
              </div>
           
              <div class="d-sm-flex justify-content-between align-items-center my-2 pb-3 border-bottom">
                <div class="d-block d-sm-flex align-items-center text-center text-sm-start">
                  <a class="d-inline-block flex-shrink-0 mx-auto me-sm-4" href="javascript:;">
                    <img src="img/home/shop/cart/02.jpg" class="radius8" width="160" alt="Product"/>
                  </a>
                  <div class="pt-2">
                    <h3 class="product-title fs-base mb-2">
                      <a href="javascript:;">Citrine (1490mg)</a>
                    </h3>
                    <div class="fs-sm"><span class="text-muted me-2">SKU:</span>40311347889</div>
                    <div class="fs-sm"><span class="text-muted me-2">Ratti:</span>12+</div>
                    <div class="fs-lg text-primary pt-2">Rs. 2500</div>
                  </div>
                </div>
                <div class="pt-2 pt-sm-0 ps-sm-3 mx-auto mx-sm-0 text-center text-sm-start" style={{maxWidth: '9rem'}}>
                  <label class="form-label" for="quantity2">Quantity</label>
                  <input class="form-control" type="number" id="quantity2" min="1" value="1"/>
                  <button class="px-0 py-3 border-0 bgwhite d-block w-100 text-danger" type="button">
                    <i class="ci-close-circle me-2"></i>
                    <span class="fs-sm">Remove</span>
                  </button>
                </div>
              </div>
     
              <div class="d-sm-flex justify-content-between align-items-center my-2 pb-3 border-bottom">
                <div class="d-block d-sm-flex align-items-center text-center text-sm-start">
                  <a class="d-inline-block flex-shrink-0 mx-auto me-sm-4" href="javascript:;">
                    <img src="img/home/shop/cart/03.jpg" class="radius8" width="160" alt="Product"/>
                  </a>
                  <div class="pt-2">
                    <h3 class="product-title fs-base mb-2">
                      <a href="javascript:;">Emerlad (1490mg)</a>
                    </h3>
                    <div class="fs-sm"><span class="text-muted me-2">SKU:</span>40311347889</div>
                    <div class="fs-sm"><span class="text-muted me-2">Ratti:</span>12+</div>
                    <div class="fs-lg text-primary pt-2">Rs. 3000</div>
                  </div>
                </div>
                <div class="pt-2 pt-sm-0 ps-sm-3 mx-auto mx-sm-0 text-center text-sm-start" style={{maxWidth: '9rem'}}>
                  <label class="form-label" for="quantity3">Quantity</label>
                  <input class="form-control" type="number" id="quantity3" min="1" value="1"/>
                  <button class="px-0 py-3 border-0 bgwhite d-block w-100 text-danger" type="button">
                    <i class="ci-close-circle me-2"></i>
                    <span class="fs-sm">Remove</span>
                  </button>
                </div>
              </div>
        \
              <div class="d-sm-flex justify-content-between align-items-center my-2 pb-3 border-bottom">
                <div class="d-block d-sm-flex align-items-center text-center text-sm-start">
                  <a class="d-inline-block flex-shrink-0 mx-auto me-sm-4" href="javascript:;">
                    <img src="img/home/shop/cart/04.jpg" class="radius8" width="160" alt="Product"/>
                  </a>
                  <div class="pt-2">
                    <h3 class="product-title fs-base mb-2">
                      <a href="javascript:;">Sapphire Yellow (1490mg)</a>
                    </h3>
                    <div class="fs-sm"><span class="text-muted me-2">SKU:</span>40311347889</div>
                    <div class="fs-sm"><span class="text-muted me-2">Ratti:</span>12+</div>
                    <div class="fs-lg text-primary pt-2">Rs. 2000</div>
                  </div>
                </div>
                <div class="pt-2 pt-sm-0 ps-sm-3 mx-auto mx-sm-0 text-center text-sm-start" style={{maxWidth: '9rem'}}>
                  <label class="form-label" for="quantity4">Quantity</label>
                  <input class="form-control" type="number" id="quantity4" min="1" value="1"/>
                  <button class="px-0 py-3 border-0 bgwhite d-block w-100 text-danger" type="button">
                    <i class="ci-close-circle me-2"></i>
                    <span class="fs-sm">Remove</span>
                  </button>
                </div>
              </div>
            </section>
            
            <aside class="col-lg-4 pt-4 pt-lg-0 ps-xl-5">
              <div class="bgwhite radius8 shadow-lg p-4">
                <div class="py-2 px-xl-2">
                  <div class="text-center mb-4 pb-3 border-bottom">
                    <h2 class="h6 mb-3 pb-1">Subtotal</h2>
                    <h3 class="fw-normal">Rs.<span>9000</span></h3>
                  </div>
                  <div class="mb-3 mb-4">
                    <label class="form-label mb-3" for="order-comments"><span class="badge bg-info fs-xs me-2">Note</span><span class="fw-medium">Additional comments</span></label>
                    <textarea class="form-control" rows="6" id="order-comments"></textarea>
                  </div>
                  <div class="accordion" id="order-options">
                    <div class="accordion-item">
                      <h3 class="accordion-header"><a class="accordion-button" href="#promo-code" role="button" data-bs-toggle="collapse" aria-expanded="true" aria-controls="promo-code">Apply promo code</a></h3>
                      <div class="accordion-collapse collapse show" id="promo-code" data-bs-parent="#order-options">
                        <form class="accordion-body needs-validation" method="post" novalidate>
                          <div class="mb-3">
                            <input class="form-control" type="text" placeholder="Promo code" required/>
                            <div class="invalid-feedback">Please provide promo code.</div>
                          </div>
                          <button class="btn btn-outline-primary d-block w-100" type="submit">Apply promo code</button>
                        </form>
                      </div>
                    </div>
                  </div>
                  <a class="btn btn-primary btn-shadow d-block w-100 mt-4" href="/checkout-details">
                    <i class="ci-card fs-lg me-2"></i>Proceed to Checkout</a>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </section>
    <FooterA/>
    </>
  )
}

export default ShopCart
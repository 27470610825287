import React from 'react';
import Navbar from '../Components/Navbar/Navbar';
import NavbarTwo from '../Components/NavbarTwo/NavbarTwo';
import FooterA from '../Components/FooterA/FooterA';


const AccountWishlist = () => {
  return (
    <>
    <Navbar/>
    <NavbarTwo/>
    <section class="page-title-overlap bgBlue pt-4 pb-5">
        <div class="container pt-2 pb-3 pt-lg-3 pb-lg-4">
          <div class="d-lg-flex justify-content-between pb-5">
              <div class="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
                    <li class="breadcrumb-item">
                      <a class="text-nowrap" href="/"><i class="ci-home"></i>Home</a>
                    </li>
                    <li class="breadcrumb-item text-nowrap"><a href="javascript:;">Account</a></li>
                    <li class="breadcrumb-item text-nowrap active" aria-current="page">Wishlist</li>
                  </ol>
                </nav>
              </div>
              <div class="order-lg-1 pe-lg-4 text-center text-lg-start">
                <h1 class="h3 text-white mb-0">My wishlist</h1>
              </div>
          </div>
        </div>
      </section>


      <div class="container pb-5 mb-2 mb-md-4">
        <div class="row">
          <aside class="col-lg-4 pt-4 pt-lg-0 pe-xl-5">
            <div class="bgwhite radius8 shadow-lg pt-1 mb-5 mb-lg-0">
              <div class="d-md-flex justify-content-between align-items-center text-center text-md-start p-4">
                <div class="d-md-flex align-items-center">
                  <div class="img-thumbnail rounded-circle position-relative flex-shrink-0 mx-auto mb-2 mx-md-0 mb-md-0" style={{width: '6.375rem'}}>
                    <img class="rounded-circle" src="img/home/gemstone/avatar.jpg" alt="Hardeep Singh"/>
                  </div>
                  <div class="ps-md-3">
                    <h3 class="fs-base mb-0">Hardeep Singh</h3>
                    <span class="text-primary fs-sm">prince.villkhu@gmail.com</span>
                  </div>
                </div>
                <a class="btn btn-primary d-lg-none mb-2 mt-3 mt-md-0" href="#account-menu" data-bs-toggle="collapse" aria-expanded="false">
                  <i class="ci-menu me-2"></i>Account menu
                </a>
              </div>

              <div class="d-lg-block collapse" id="account-menu">
                <div class="bg-secondary px-4 py-3">
                  <h3 class="fs-sm mb-0 text-muted">Dashboard</h3>
                </div>
                <ul class="list-unstyled mb-0">
                  <li class="border-bottom mb-0">
                    <a class="nav-link-style d-flex align-items-center px-4 py-3" href="/account-orders"><i class="ci-bag opacity-60 me-2"></i>Orders
                      <span class="fs-sm text-muted ms-auto">1</span>
                    </a>
                  </li>
                  <li class="border-bottom mb-0">
                    <a class="nav-link-style d-flex align-items-center px-4 py-3 active" href="/account-wishlist"><i class="ci-heart opacity-60 me-2"></i>Wishlist
                      <span class="fs-sm text-muted ms-auto">3</span>
                    </a>
                  </li>
                </ul>
                <div class="bg-secondary px-4 py-3">
                  <h3 class="fs-sm mb-0 text-muted">Account settings</h3>
                </div>
                <ul class="list-unstyled mb-0">
                  <li class="border-bottom mb-0">
                    <a class="nav-link-style d-flex align-items-center px-4 py-3" href="/account-profile"><i class="ci-user opacity-60 me-2"></i>Profile info</a>
                  </li>
                  <li class="border-bottom mb-0">
                    <a class="nav-link-style d-flex align-items-center px-4 py-3" href="/account-address"><i class="ci-location opacity-60 me-2"></i>Addresses</a>
                  </li>
                  <li class="mb-0">
                    <a class="nav-link-style d-flex align-items-center px-4 py-3" href="/account-payment"><i class="ci-card opacity-60 me-2"></i>Payment methods</a>
                    </li>
                  <li class="d-lg-none border-top mb-0">
                    <a class="nav-link-style d-flex align-items-center px-4 py-3" href="/login"><i class="ci-sign-out opacity-60 me-2"></i>Sign out</a>
                  </li>
                </ul>
              </div>
            </div>
          </aside>
        
          <section class="col-lg-8">
          
            <div class="d-flex justify-content-between align-items-center pt-lg-2 pb-4 pb-lg-5 mb-lg-3">
              <h6 class="font-16 text-white mb-0">List of items you added to wishlist:</h6>
              <a class="btn btn-primary btn-sm d-none d-lg-inline-block" href="/login"><i class="ci-sign-out me-2"></i>Sign out</a>
            </div>
          
              <div class="d-sm-flex justify-content-between my-4 pb-3 border-bottom">
                <div class="d-sm-flex text-center text-sm-start"><a class="d-inline-block flex-shrink-0 mx-auto me-sm-4" href="/shop-single"><img src="img/home/shop/cart/01.jpg" class="radius8" width="160" alt="Product"/></a>
                  <div class="pt-2">
                    <h3 class="product-title fs-base mb-2"><a href="/shop-single">Amethyst (1490mg)</a></h3>
                    <div class="fs-sm"><span class="text-muted me-2">SKU:</span>40311347889</div>
                    <div class="fs-sm"><span class="text-muted me-2">Ratti:</span>12+</div>
                    <div class="fs-lg text-primary pt-2">Rs..<span>1500</span></div>
                  </div>
                </div>
                <div class="pt-2 ps-sm-3 mx-auto mx-sm-0 text-center">
                  <button class="btn btn-outline-danger btn-sm" type="button">
                  <i class="ci-trash me-2"></i>Remove
                  </button>
                </div>
              </div>
            
              <div class="d-sm-flex justify-content-between my-4 pb-3 border-bottom">
                <div class="d-sm-flex text-center text-sm-start"><a class="d-inline-block flex-shrink-0 mx-auto me-sm-4" href="/shop-single"><img src="img/home/shop/cart/02.jpg" class="radius8" width="160" alt="Product"/></a>
                  <div class="pt-2">
                    <h3 class="product-title fs-base mb-2"><a href="/shop-single">Citrine (1490mg)</a></h3>
                    <div class="fs-sm"><span class="text-muted me-2">SKU:</span>40311347889</div>
                    <div class="fs-sm"><span class="text-muted me-2">Ratti:</span>12+</div>
                    <div class="fs-lg text-primary pt-2">Rs..<span>2500</span></div>
                  </div>
                </div>
                <div class="pt-2 ps-sm-3 mx-auto mx-sm-0 text-center">
                  <button class="btn btn-outline-danger btn-sm" type="button">
                  <i class="ci-trash me-2"></i>Remove
                  </button>
                </div>
              </div>

              <div class="d-sm-flex justify-content-between my-4 pb-3">
                <div class="d-sm-flex text-center text-sm-start"><a class="d-inline-block flex-shrink-0 mx-auto me-sm-4" href="/shop-single"><img src="img/home/shop/cart/03.jpg" class="radius8" width="160" alt="Product"/></a>
                  <div class="pt-2">
                    <h3 class="product-title fs-base mb-2"><a href="/shop-single">Emerlad (1490mg)</a></h3>
                    <div class="fs-sm"><span class="text-muted me-2">SKU:</span>40311347889</div>
                    <div class="fs-sm"><span class="text-muted me-2">Ratti:</span>12+</div>
                    <div class="fs-lg text-primary pt-2">Rs..<span>3000</span></div>
                  </div>
                </div>
                <div class="pt-2 ps-sm-3 mx-auto mx-sm-0 text-center">
                  <button class="btn btn-outline-danger btn-sm" type="button">
                  <i class="ci-trash me-2"></i>Remove
                  </button>
                </div>
              </div>
             
              <nav class="d-flex justify-content-between pt-2" aria-label="Page navigation">
                <ul class="pagination">
                  <li class="page-item"><a class="page-link" href="#"><i class="ci-arrow-left me-2"></i>Prev</a></li>
                </ul>
                <ul class="pagination">
                  <li class="page-item d-sm-none"><span class="page-link page-link-static">1 / 5</span></li>
                  <li class="page-item active d-none d-sm-block" aria-current="page"><span class="page-link">1<span class="visually-hidden">(current)</span></span></li>
                  <li class="page-item d-none d-sm-block"><a class="page-link" href="#">2</a></li>
                  <li class="page-item d-none d-sm-block"><a class="page-link" href="#">3</a></li>
                  <li class="page-item d-none d-sm-block"><a class="page-link" href="#">4</a></li>
                  <li class="page-item d-none d-sm-block"><a class="page-link" href="#">5</a></li>
                </ul>
                <ul class="pagination">
                  <li class="page-item"><a class="page-link" href="#" aria-label="Next">Next<i class="ci-arrow-right ms-2"></i></a></li>
                </ul>
              </nav>
          </section>
        </div>
      </div>
      <FooterA/>
    </>
  )
}

export default AccountWishlist
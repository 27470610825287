import React from 'react';
import Navbar from '../Components/Navbar/Navbar';
import NavbarTwo from '../Components/NavbarTwo/NavbarTwo';
import FooterA from '../Components/FooterA/FooterA';
const OrderView = () => {
  return (
   <>
   <Navbar/>
   <NavbarTwo/>
      <section class="page-title-overlap bgBlue pt-4 pb-5">
        <div class="container pt-2 pb-3 pt-lg-3 pb-lg-4">
          <div class="d-lg-flex justify-content-between pb-5">
              <div class="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
                    <li class="breadcrumb-item">
                      <a class="text-nowrap" href="/"><i class="ci-home"></i>Home</a>
                    </li>
                    <li class="breadcrumb-item text-nowrap"><a href="javascript:;">Account</a></li>
                    <li class="breadcrumb-item text-nowrap"><a href="javascript:;">My Orders</a></li>
                    <li class="breadcrumb-item text-nowrap active" aria-current="page">Order View</li>
                  </ol>
                </nav>
              </div>
              <div class="order-lg-1 pe-lg-4 text-center text-lg-start">
                <h1 class="h3 text-white mb-0">Order View</h1>
              </div>
          </div>
        </div>
      </section>


      <div class="container pb-5 mb-2 mb-md-4">
        <div class="row">
        
          <aside class="col-lg-12 pt-4 pt-lg-0 pe-xl-5">
            <div class="bgwhite radius8 shadow-lg p-3 p-md-5 text-center">

              <div class="border-bottom position-relative pb-3 d-block d-sm-flex align-items-center justify-content-center">
                <a class="btn btn-secondary orderViewBackBtn mb-3 mb-sm-0" href="/account-orders">
                  <i class="ci-arrow-left mt-sm-0 me-1"></i>
                  <span>Back</span>
                </a>
                <h4 class="mb-0 text-center">Order Details</h4>
              </div>

              <div class="d-sm-flex justify-content-between my-4 pb-4 border-bottom">
                <div class="d-sm-flex text-center text-sm-start"><a class="d-inline-block flex-shrink-0 mx-auto me-sm-4" href="/shop-single"><img src="img/home/shop/cart/01.jpg" class="radius8" width="160" alt="Product"/></a>
                  <div class="pt-2">
                    <h3 class="product-title fs-base mb-2"><a href="/shop-single">Amethyst (1490mg)</a></h3>
                    <div class="fs-sm"><span class="text-muted me-2">SKU:</span>40311347889</div>
                    <div class="fs-sm"><span class="text-muted me-2">Ratti:</span>12+</div>
                    <div class="fs-lg text-primary pt-2">Rs..<span>1500</span></div>
                  </div>
                </div>
                <div class="pt-2 pt-sm-0 ps-sm-3 mx-auto mx-sm-0 text-center text-sm-end">
                  <p class="d-block w-100 text-danger mb-1"><span class="fs-sm">Order ID :  34VB5540K83</span></p>
                  <p class="mb-0"><span class="text-muted fs-sm">Placed On : May 21, 2024</span></p>
                </div>
              </div>

              <div class="bg-secondary radius8 px-4 pt-4 pb-2">
                <div class="row">
                  <div class="col-sm-4">
                    <h4 class="h6">Shipping to:</h4>
                    <ul class="list-unstyled fs-sm">
                      <li><span class="text-muted">Client:&nbsp;</span>Susan Gardner</li>
                      <li><span class="text-muted">Address:&nbsp;</span>44 Shirley Ave. West Chicago, IL 60185, USA</li>
                      <li><span class="text-muted">Phone:&nbsp;</span>+1 (808) 764 554 330</li>
                    </ul>
                  </div>
                  <div class="col-sm-4">
                    <h4 class="h6">Billing to:</h4>
                    <ul class="list-unstyled fs-sm">
                      <li><span class="text-muted">Client:&nbsp;</span>Susan Gardner</li>
                      <li><span class="text-muted">Address:&nbsp;</span>44 Shirley Ave. West Chicago, IL 60185, USA</li>
                      <li><span class="text-muted">Phone:&nbsp;</span>+1 (808) 764 554 330</li>
                    </ul>
                  </div>
                  <div class="col-sm-4">
                    <h4 class="h6">Payment method:</h4>
                    <ul class="list-unstyled fs-sm">
                      <li class="mb-3"><span class="text-muted">Credit Card:&nbsp;</span>**** **** **** 5300</li>
                      <li><button class="btn btn-outline-primary btn-sm" type="submit">Retry Payment</button></li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="mt-4">
                <div class="row">
                  <div class="col-lg-4 offset-lg-8">
                    <div>
                      <div class="widget mb-3">
                        <h2 class="widget-title text-center">Order summary</h2>
                      </div>
                      <ul class="list-unstyled fs-sm mb-0">
                        <li class="d-flex justify-content-between align-items-center">
                          <span class="me-2">Subtotal:</span>
                          <span class="text-end">Rs.<span>8000</span></span>
                        </li>
                        <li class="d-flex justify-content-between align-items-center">
                          <span class="me-2">Shipping:</span>
                          <span class="text-end">Rs.<span>100</span></span>
                        </li>
                        <li class="d-flex justify-content-between align-items-center">
                          <span class="me-2">Taxes:</span>
                          <span class="text-end">Rs.<span>1500</span></span>
                        </li>
                        <li class="d-flex justify-content-between align-items-center">
                          <span class="me-2">Promo Code Applied:</span>
                          <span class="text-end">Rs.<span>200</span></span>
                        </li>
                        <li class="d-flex justify-content-between align-items-center">
                          <span class="me-2">Grand Total:</span>
                          <span class="text-end font18 text-danger">Rs.<span>9800</span></span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </aside>
        </div>
      </div>
      <FooterA/>
   </>
  )
}

export default OrderView
import React from 'react'
import Navbar from '../Components/Navbar/Navbar';
import NavbarTwo from '../Components/NavbarTwo/NavbarTwo';
import FooterA from '../Components/FooterA/FooterA';
const AccountPaymet = () => {
  return (
   <>
   <Navbar/>
   <NavbarTwo/>
     <section class="page-title-overlap bgBlue pt-4 pb-5">
        <div class="container pt-2 pb-3 pt-lg-3 pb-lg-4">
          <div class="d-lg-flex justify-content-between pb-5">
              <div class="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
                    <li class="breadcrumb-item">
                      <a class="text-nowrap" href="/"><i class="ci-home"></i>Home</a>
                    </li>
                    <li class="breadcrumb-item text-nowrap"><a href="javascript:;">Account</a></li>
                    <li class="breadcrumb-item text-nowrap active" aria-current="page">Payment methods</li>
                  </ol>
                </nav>
              </div>
              <div class="order-lg-1 pe-lg-4 text-center text-lg-start">
                <h1 class="h3 text-white mb-0">My payment methods</h1>
              </div>
          </div>
        </div>
      </section>


      <div class="container pb-5 mb-2 mb-md-4">
        <div class="row">
         
          <aside class="col-lg-4 pt-4 pt-lg-0 pe-xl-5">
            <div class="bgwhite radius8 shadow-lg pt-1 mb-5 mb-lg-0">
              <div class="d-md-flex justify-content-between align-items-center text-center text-md-start p-4">
                <div class="d-md-flex align-items-center">
                  <div class="img-thumbnail rounded-circle position-relative flex-shrink-0 mx-auto mb-2 mx-md-0 mb-md-0" style={{width: '6.375rem'}}>
                    <img class="rounded-circle" src="img/home/gemstone/avatar.jpg" alt="Hardeep Singh"/>
                  </div>
                  <div class="ps-md-3">
                    <h3 class="fs-base mb-0">Hardeep Singh</h3>
                    <span class="text-primary fs-sm">prince.villkhu@gmail.com</span>
                  </div>
                </div>
                <a class="btn btn-primary d-lg-none mb-2 mt-3 mt-md-0" href="#account-menu" data-bs-toggle="collapse" aria-expanded="false">
                  <i class="ci-menu me-2"></i>Account menu
                </a>
              </div>

              <div class="d-lg-block collapse" id="account-menu">
                <div class="bg-secondary px-4 py-3">
                  <h3 class="fs-sm mb-0 text-muted">Dashboard</h3>
                </div>
                <ul class="list-unstyled mb-0">
                  <li class="border-bottom mb-0">
                    <a class="nav-link-style d-flex align-items-center px-4 py-3" href="/account-orders"><i class="ci-bag opacity-60 me-2"></i>Orders
                      <span class="fs-sm text-muted ms-auto">1</span>
                    </a>
                  </li>
                  <li class="border-bottom mb-0">
                    <a class="nav-link-style d-flex align-items-center px-4 py-3" href="/account-wishlist"><i class="ci-heart opacity-60 me-2"></i>Wishlist
                      <span class="fs-sm text-muted ms-auto">3</span>
                    </a>
                  </li>
                </ul>
                <div class="bg-secondary px-4 py-3">
                  <h3 class="fs-sm mb-0 text-muted">Account settings</h3>
                </div>
                <ul class="list-unstyled mb-0">
                  <li class="border-bottom mb-0">
                    <a class="nav-link-style d-flex align-items-center px-4 py-3" href="/account-profile"><i class="ci-user opacity-60 me-2"></i>Profile info</a>
                  </li>
                  <li class="border-bottom mb-0">
                    <a class="nav-link-style d-flex align-items-center px-4 py-3" href="/account-address"><i class="ci-location opacity-60 me-2"></i>Addresses</a>
                  </li>
                  <li class="mb-0">
                    <a class="nav-link-style d-flex align-items-center px-4 py-3 active" href="/account-payment"><i class="ci-card opacity-60 me-2"></i>Payment methods</a>
                    </li>
                  <li class="d-lg-none border-top mb-0">
                    <a class="nav-link-style d-flex align-items-center px-4 py-3" href="/login"><i class="ci-sign-out opacity-60 me-2"></i>Sign out</a>
                  </li>
                </ul>
              </div>
            </div>
          </aside>
          <section class="col-lg-8">
            <div class="d-flex justify-content-between align-items-center pt-lg-2 pb-4 pb-lg-5 mb-lg-3">
              <h6 class="font-16 text-white mb-0">Primary payment method is used by default</h6>
              <a class="btn btn-primary btn-sm d-none d-lg-inline-block" href="/login"><i class="ci-sign-out me-2"></i>Sign out</a>
            </div>
                      <div class="table-responsive fs-md mb-4">
              <table class="table table-hover mb-0">
                <thead>
                  <tr>
                    <th>Your credit / debit cards</th>
                    <th>Name on card</th>
                    <th>Expires on</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="py-3 align-middle">
                      <div class="d-flex align-items-center"><img src="img/home/gemstone/card-visa.png" width="39" alt="Visa"/>
                        <div class="ps-2"><span class="fw-medium text-heading me-1">Visa</span>ending in 4999<span class="align-middle badge bg-info ms-2">Primary</span></div>
                      </div>
                    </td>
                    <td class="py-3 align-middle">Hardeep Singh</td>
                    <td class="py-3 align-middle">08 / 2024</td>
                    <td class="py-3 align-middle"><a class="nav-link-style me-2" href="#" data-bs-toggle="tooltip" title="Edit"><i class="ci-edit"></i></a><a class="nav-link-style text-danger" href="#" data-bs-toggle="tooltip" title="Remove">
                        <div class="ci-trash"></div></a></td>
                  </tr>
                  <tr>
                    <td class="py-3 align-middle">
                      <div class="d-flex align-items-center"><img src="img/home/gemstone/card-master.png" width="39" alt="MesterCard"/>
                        <div class="ps-2"><span class="fw-medium text-heading me-1">MasterCard</span>ending in 0015</div>
                      </div>
                    </td>
                    <td class="py-3 align-middle">Hardeep Singh</td>
                    <td class="py-3 align-middle">11 / 2024</td>
                    <td class="py-3 align-middle"><a class="nav-link-style me-2" href="#" data-bs-toggle="tooltip" title="Edit"><i class="ci-edit"></i></a><a class="nav-link-style text-danger" href="#" data-bs-toggle="tooltip" title="Remove">
                        <div class="ci-trash"></div></a></td>
                  </tr>
                  <tr>
                    <td class="py-3 align-middle">
                      <div class="d-flex align-items-center"><img src="img/home/gemstone/card-paypal.png" width="39" alt="PayPal"/>
                        <div class="ps-2"><span class="fw-medium text-heading me-1">PayPal</span>s.gardner@example.com</div>
                      </div>
                    </td>
                    <td class="py-3 align-middle">&mdash;</td>
                    <td class="py-3 align-middle">&mdash;</td>
                    <td class="py-3 align-middle"><a class="nav-link-style me-2" href="#" data-bs-toggle="tooltip" title="Edit"><i class="ci-edit"></i></a><a class="nav-link-style text-danger" href="#" data-bs-toggle="tooltip" title="Remove">
                        <div class="ci-trash"></div></a></td>
                  </tr>
                  <tr>
                    <td class="py-3 align-middle">
                      <div class="d-flex align-items-center"><img src="img/home/gemstone/card-visa.png" width="39" alt="Visa"/>
                        <div class="ps-2"><span class="fw-medium text-heading me-1">Visa</span>ending in 6073</div>
                      </div>
                    </td>
                    <td class="py-3 align-middle">Hardeep Singh</td>
                    <td class="py-3 align-middle">09 / 2024</td>
                    <td class="py-3 align-middle"><a class="nav-link-style me-2" href="#" data-bs-toggle="tooltip" title="Edit"><i class="ci-edit"></i></a><a class="nav-link-style text-danger" href="#" data-bs-toggle="tooltip" title="Remove">
                        <div class="ci-trash"></div></a></td>
                  </tr>
                  <tr>
                    <td class="py-3 align-middle">
                      <div class="d-flex align-items-center"><img src="img/home/gemstone/card-visa.png" width="39" alt="Visa"/>
                        <div class="ps-2"><span class="fw-medium text-heading me-1">Visa</span>ending in 9791</div>
                      </div>
                    </td>
                    <td class="py-3 align-middle">Hardeep Singh</td>
                    <td class="py-3 align-middle">05 / 2024</td>
                    <td class="py-3 align-middle"><a class="nav-link-style me-2" href="#" data-bs-toggle="tooltip" title="Edit"><i class="ci-edit"></i></a><a class="nav-link-style text-danger" href="#" data-bs-toggle="tooltip" title="Remove">
                        <div class="ci-trash"></div></a></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="text-sm-end"><a class="btn btn-primary" href="#add-payment" data-bs-toggle="modal">Add payment method</a></div>
          </section>
        </div>
      </div>
<FooterA/>
   </>
  )
}

export default AccountPaymet
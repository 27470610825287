import React from 'react';
import  TinySlider  from 'tiny-slider-react';
import 'tiny-slider/dist/tiny-slider.css';
import Slide1 from '../../../Assests/img/home/gemstone/slide-1.png'; // Update this path to the actual location of your image

const sliderSettings = {
  items: 1,
  controls: false,
  loop: false,
};

const HeroSection = () => {
  return (
    <section className="bgLightGray py-5 slide-bg">
      <div className="container py-xl-2">
        <div className="row">
          <div className="col-xl-12 pt-xl-5">
            <TinySlider settings={sliderSettings}>
              <div>
                <div className="row align-items-center">
                  <div className="col-md-6 order-md-2">
                    <img className="d-block mx-auto" src={Slide1} alt="gemstone-1" />
                  </div>
                  <div className="col-lg-6 col-md-6 order-md-1 pt-4 pb-md-4 text-center text-md-start">
                    <h2 className="font16 letterSpacing2 radius4 bgwhite d-inline-block mb-4 px-2 py-1 text-uppercase from-bottom">
                      Welcome to the world of gemstone
                    </h2>
                    <h1 className="font70 from-bottom delay-1 mb-4">
                      Natural & Certified <span className="textRed">Gemstone</span> Shop
                    </h1>
                    <div className="d-table scale-up delay-4 mx-auto mx-md-0">
                      <a className="btn btn-outline-primary btn-shadow text-uppercase ms-1" href="#">
                        Shop Now<i className="ci-arrow-right ms-2 me-n1"> </i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="row align-items-center">
                  <div className="col-md-6 order-md-2">
                    <img className="d-block mx-auto" src={Slide1} alt="gemstone-1" />
                  </div>
                  <div className="col-lg-6 col-md-6 order-md-1 pt-4 pb-md-4 text-center text-md-start">
                    <h2 className="font16 letterSpacing2 radius4 bgwhite d-inline-block mb-4 px-2 py-1 text-uppercase from-start">
                      Welcome to the world of gemstone
                    </h2>
                    <h1 className="font70 from-start delay-1 mb-4">
                      Natural & Certified <span className="textRed">Gemstone</span> Shop
                    </h1>
                    <div className="d-table scale-up delay-4 mx-auto mx-md-0">
                      <a className="btn btn-outline-primary btn-shadow text-uppercase ms-1" href="#">
                        Shop Now <i className="ci-arrow-right ms-2 me-n1"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="row align-items-center">
                  <div className="col-md-6 order-md-2">
                    <img className="d-block mx-auto" src={Slide1} alt="gemstone-1" />
                  </div>
                  <div className="col-lg-6 col-md-6 order-md-1 pt-4 pb-md-4 text-center text-md-start">
                    <h2 className="font16 letterSpacing2 radius4 bgwhite d-inline-block mb-4 px-2 py-1 text-uppercase scale-up">
                      Welcome to the world of gemstone
                    </h2>
                    <h1 className="font70 scale-up delay-1 mb-4">
                      Natural & Certified <span className="textRed">Gemstone</span> Shop
                    </h1>
                    <div className="d-table scale-up delay-4 mx-auto mx-md-0">
                      <a className="btn btn-outline-primary btn-shadow text-uppercase ms-1" href="#">
                        Shop Now <i className="ci-arrow-right ms-2 me-n1"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </TinySlider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;

import React from 'react'
import Dark from "../../Assests/img/logo-dark.png";
import Logo from "../../Assests/img/logo-dark.png";
import { Link } from 'react-router-dom';

const NavbarTwo = () => {
  return (
    <>
     <header class="shadow-sm">
        <div class="navbar-sticky bg-light">
          <div class="navbar navbar-expand-lg navbar-light bgwhite">
            <div class="container">
              <a class="navbar-brand d-none d-sm-block flex-shrink-0" href="/">
                <img src={Dark} width="100" alt="9 Gem"/>
              </a>
              <a class="navbar-brand d-sm-none flex-shrink-0 me-2" href="/">
                <img src={Logo} width="74" alt="9 Gem"/>
              </a>
              <div class="input-group d-none d-lg-flex mx-4">
                <input class="form-control rounded-end pe-5" type="text" placeholder="Search for products"/><i class="ci-search position-absolute top-50 end-0 translate-middle-y text-muted fs-base me-3"></i>
              </div>
              <div class="navbar-toolbar d-flex flex-shrink-0 align-items-center">
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                  <span class="navbar-toggler-icon"></span>
                </button>
                <a class="navbar-tool navbar-stuck-toggler" href="">
                  <span class="navbar-tool-tooltip">Expand menu</span>
                  <div class="navbar-tool-icon-box">
                    <i class="navbar-tool-icon ci-menu"></i>
                  </div>
                </a>
                <a class="navbar-tool d-none d-lg-flex" href="javascript:;">
                  <span class="navbar-tool-tooltip">Wishlist</span>
                  <div class="navbar-tool-icon-box">
                    <i class="navbar-tool-icon ci-heart"></i>
                  </div>
                </a>
                <a class="navbar-tool ms-1 me-n1" href="/login">
                  <span class="navbar-tool-tooltip">Account</span>
                  <div class="navbar-tool-icon-box">
                    <i class="navbar-tool-icon ci-user"></i>
                  </div>
                </a>
                <div class="navbar-tool dropdown ms-3">
                  <a class="navbar-tool-icon-box bg-secondary dropdown-toggle" href="/shop-cart">
                    <span class="navbar-tool-label">4</span><i class="navbar-tool-icon ci-cart"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="navbar navbar-expand-lg navbar-light navbar-stuck-menu mt-n2 pt-0 pb-2 bgwhite">
            <div class="container">
              <div class="collapse navbar-collapse" id="navbarCollapse">
             
                <div class="input-group d-lg-none my-3"><i class="ci-search position-absolute top-50 start-0 translate-middle-y text-muted fs-base ms-3"></i>
                  <input class="form-control radius4s" type="text" placeholder="Search for products"/>
                </div>
                <ul class="navbar-nav">
              <li class="nav-item dropdown">
                <a class="nav-link" href="/">Home</a>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" data-bs-auto-close="outside">Gemstons</a>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" href="#">Amethyst</a></li>
                  <li><a class="dropdown-item" href="#">Cats Eye</a></li>
                  <li><a class="dropdown-item" href="#">Citrine</a></li>
                  <li><a class="dropdown-item" href="#">Coral</a></li>
                  <li><a class="dropdown-item" href="#">Coral Triangular</a></li>
                  <li><a class="dropdown-item" href="#">Emerald</a></li>
                  <li><a class="dropdown-item" href="#">Hessonite</a></li>
                  <li><a class="dropdown-item" href="#">Iolite</a></li>
                  <li><a class="dropdown-item" href="#">Opal</a></li>
                  <li><a class="dropdown-item" href="#">Pearl</a></li>
                  <li><a class="dropdown-item" href="#">Pearl Natural</a></li>
                  <li><a class="dropdown-item" href="#">Ruby</a></li>
                  <li><a class="dropdown-item" href="#">Sapphire</a></li>
                  <li><a class="dropdown-item" href="#">Sapphire Blue</a></li>
                  <li><a class="dropdown-item" href="#">Sapphire Pink</a></li>
                  <li><a class="dropdown-item" href="#">Sapphire White</a></li>
                  <li><a class="dropdown-item" href="#">Sapphire Yellow</a></li>
                  <li><a class="dropdown-item" href="#">Topaz</a></li>
                  <li><a class="dropdown-item" href="#">Turquoise</a></li>
                  <li><a class="dropdown-item" href="#">Zircon</a></li>
                </ul>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" data-bs-auto-close="outside">SOP Category</a>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" href="#">Category 1</a></li>
                  <li><a class="dropdown-item" href="#">Category 2</a></li>
                  <li><a class="dropdown-item" href="#">Category 3</a></li>
                  <li><a class="dropdown-item" href="#">Category 4</a></li>
                </ul>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" data-bs-auto-close="outside">Policies</a>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" href="#">Category 1</a></li>
                  <li><a class="dropdown-item" href="#">Category 2</a></li>
                  <li><a class="dropdown-item" href="#">Category 3</a></li>
                  <li><a class="dropdown-item" href="#">Category 4</a></li>
                </ul>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" data-bs-auto-close="outside">Management</a>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" href="#">Category 1</a></li>
                  <li><a class="dropdown-item" href="#">Category 2</a></li>
                  <li><a class="dropdown-item" href="#">Category 3</a></li>
                  <li><a class="dropdown-item" href="#">Category 4</a></li>
                </ul>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link" href="/blog">Blog</a>
              </li>
            </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default NavbarTwo
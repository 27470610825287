import React from 'react'
import Navbar from '../Components/Navbar/Navbar';
import NavbarTwo from '../Components/NavbarTwo/NavbarTwo';
import FooterA from '../Components/FooterA/FooterA';
const AccountProfile = () => {
  return (
    <>
    <Navbar/>
    <NavbarTwo/>
     <section class="page-title-overlap bgBlue pt-4 pb-5">
        <div class="container pt-2 pb-3 pt-lg-3 pb-lg-4">
          <div class="d-lg-flex justify-content-between pb-5">
              <div class="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
                    <li class="breadcrumb-item">
                      <a class="text-nowrap" href="/"><i class="ci-home"></i>Home</a>
                    </li>
                    <li class="breadcrumb-item text-nowrap"><a href="javascript:;">Account</a></li>
                    <li class="breadcrumb-item text-nowrap active" aria-current="page">Profile info</li>
                  </ol>
                </nav>
              </div>
              <div class="order-lg-1 pe-lg-4 text-center text-lg-start">
                <h1 class="h3 text-white mb-0">Profile info</h1>
              </div>
          </div>
        </div>
      </section>


      <div class="container pb-5 mb-2 mb-md-4">
        <div class="row">
        
          <aside class="col-lg-4 pt-4 pt-lg-0 pe-xl-5">
            <div class="bgwhite radius8 shadow-lg pt-1 mb-5 mb-lg-0">
              <div class="d-md-flex justify-content-between align-items-center text-center text-md-start p-4">
                <div class="d-md-flex align-items-center">
                  <div class="img-thumbnail rounded-circle position-relative flex-shrink-0 mx-auto mb-2 mx-md-0 mb-md-0" style={{width: '6.375rem'}}>
                    <img class="rounded-circle" src="img/home/gemstone/avatar.jpg" alt="Hardeep Singh"/>
                  </div>
                  <div class="ps-md-3">
                    <h3 class="fs-base mb-0">Hardeep Singh</h3>
                    <span class="text-primary fs-sm">prince.villkhu@gmail.com</span>
                  </div>
                </div>
                <a class="btn btn-primary d-lg-none mb-2 mt-3 mt-md-0" href="#account-menu" data-bs-toggle="collapse" aria-expanded="false">
                  <i class="ci-menu me-2"></i>Account menu
                </a>
              </div>

              <div class="d-lg-block collapse" id="account-menu">
                <div class="bg-secondary px-4 py-3">
                  <h3 class="fs-sm mb-0 text-muted">Dashboard</h3>
                </div>
                <ul class="list-unstyled mb-0">
                  <li class="border-bottom mb-0">
                    <a class="nav-link-style d-flex align-items-center px-4 py-3" href="/account-orders"><i class="ci-bag opacity-60 me-2"></i>Orders
                      <span class="fs-sm text-muted ms-auto">1</span>
                    </a>
                  </li>
                  <li class="border-bottom mb-0">
                    <a class="nav-link-style d-flex align-items-center px-4 py-3" href="/account-wishlist"><i class="ci-heart opacity-60 me-2"></i>Wishlist
                      <span class="fs-sm text-muted ms-auto">3</span>
                    </a>
                  </li>
                </ul>
                <div class="bg-secondary px-4 py-3">
                  <h3 class="fs-sm mb-0 text-muted">Account settings</h3>
                </div>
                <ul class="list-unstyled mb-0">
                  <li class="border-bottom mb-0">
                    <a class="nav-link-style d-flex align-items-center px-4 py-3 active" href="/account-profile"><i class="ci-user opacity-60 me-2"></i>Profile info</a>
                  </li>
                  <li class="border-bottom mb-0">
                    <a class="nav-link-style d-flex align-items-center px-4 py-3" href="/account-address"><i class="ci-location opacity-60 me-2"></i>Addresses</a>
                  </li>
                  <li class="mb-0">
                    <a class="nav-link-style d-flex align-items-center px-4 py-3" href="/account-payment"><i class="ci-card opacity-60 me-2"></i>Payment methods</a>
                    </li>
                  <li class="d-lg-none border-top mb-0">
                    <a class="nav-link-style d-flex align-items-center px-4 py-3" href="/login"><i class="ci-sign-out opacity-60 me-2"></i>Sign out</a>
                  </li>
                </ul>
              </div>
            </div>
          </aside>
        
          <section class="col-lg-8">
            
            <div class="d-flex justify-content-between align-items-center pt-lg-2 pb-4 pb-lg-5 mb-lg-3">
              <h6 class="font-16 text-white mb-0">Update you profile details below:</h6>
              <a class="btn btn-primary btn-sm d-none d-lg-inline-block" href="/login"><i class="ci-sign-out me-2"></i>Sign out</a>
            </div>
             <form>
              <div class="bg-secondary rounded-3 p-4 mb-4">
                <div class="d-flex align-items-center"><img class="rounded-circle" src="img/home/gemstone/avatar.jpg" width="90" alt="Hardeep Singh"/>
                  <div class="ps-3">
                    <button class="btn btn-light btn-shadow btn-sm mb-2" type="button"><i class="ci-loading me-2"></i>Change avatar</button>
                    <div class="p mb-0 fs-ms text-muted">Upload JPG, GIF or PNG image. 300 x 300 required.</div>
                  </div>
                </div>
              </div>
              <div class="row gx-4 gy-3">
                <div class="col-sm-6">
                  <label class="form-label" for="account-fn">Full Name</label>
                  <input class="form-control" type="text" value="Hardeep Singh"/>
                </div>
                <div class="col-sm-6">
                  <label class="form-label" for="account-phone">Phone Number</label>
                  <input class="form-control" type="text" value="8284040490"/>
                </div>
                <div class="col-sm-6">
                  <label class="form-label" for="account-email">Email Address</label>
                  <input class="form-control" type="email" value="prince.villkhu@gmail.com"/>
                </div>
                <div class="col-sm-6">
                  <label class="form-label invisible d-none d-sm-inline-block" for="account-email">Email Address</label>
                  <div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" id="ex-radio-4" name="radio2"checked/>
                      <label class="form-check-label" for="ex-radio-4">Male</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" id="ex-radio-5" name="radio2"/>
                      <label class="form-check-label" for="ex-radio-5">Female</label>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <label class="form-label" for="account-pass">New Password</label>
                  <div class="password-toggle">
                    <input class="form-control" type="password"/>
                    <label class="password-toggle-btn" aria-label="Show/hide password">
                      <input class="password-toggle-check" type="checkbox"/><span class="password-toggle-indicator"></span>
                    </label>
                  </div>
                </div>
                <div class="col-sm-6">
                  <label class="form-label" for="account-confirm-pass">Confirm Password</label>
                  <div class="password-toggle">
                    <input class="form-control" type="password" id="account-confirm-pass"/>
                    <label class="password-toggle-btn" aria-label="Show/hide password">
                      <input class="password-toggle-check" type="checkbox"/><span class="password-toggle-indicator"></span>
                    </label>
                  </div>
                </div>
                <div class="col-12">
                  <hr class="mt-2 mb-3"/>
                  <div class="d-flex flex-wrap justify-content-between align-items-center">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="subscribe_me" checked/>
                      <label class="form-check-label" for="subscribe_me">Subscribe me to Newsletter</label>
                    </div>
                    <button class="btn btn-primary mt-3 mt-sm-0" type="button">Update profile</button>
                  </div>
                </div>
              </div>
            </form>
          </section>
        </div>
      </div>
      <FooterA/>
    </>
  )
}

export default AccountProfile
import React from 'react'

const Login = () => {
  return (
    <>
    
    <section class="d-flex justify-content-center align-items-center h-100">
      <div class="lgoinSignMain">
        <div class="d-flex justify-content-center">
          <a href="/">
            <img src="img/logo-dark.png" width="100" alt="9 Gem"/>
          </a>
        </div>
        <div class="card my-3">
          <div class="card-body">
            <h5><i class="ci-unlocked me-2 mt-n1"></i>Sign in</h5>
            <form>
              <div class="mb-3">
                <label class="form-label font16">Email or Mobile phone number</label>
                <input class="form-control" type="email" placeholder=""/>
              </div>
              <a href="/login-password" class="btn btn-primary btn-shadow d-block w-100" type="submit">Continue</a>
            </form>
          </div>
        </div>
        <div class="text-center">
          <p class="mb-3 font14"> Don't have an account? </p>
          <p class="mb-0"><a href="/register" class="btn btn-outline-dark btn-shadow d-block w-100" type="submit">Create your account</a></p>
        </div>
      </div>
    </section>
    </>
  )
}

export default Login
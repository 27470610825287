import React from 'react';
import Navbar from '../Components/Navbar/Navbar';
import NavbarTwo from '../Components/NavbarTwo/NavbarTwo';
import FooterA from '../Components/FooterA/FooterA';
const CheckoutDetails = () => {
    return (
        <>
<Navbar/>
<NavbarTwo/>
            <section class="page-title-overlap bgBlue pt-4 pb-5">
                <div class="container pt-2 pb-3 pt-lg-3 pb-lg-4">
                    <div class="d-lg-flex justify-content-between pb-3">
                        <div class="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
                                    <li class="breadcrumb-item">
                                        <a class="text-nowrap" href="/"><i class="ci-home"></i>Home</a>
                                    </li>
                                    <li class="breadcrumb-item text-nowrap"><a href="/products">Products</a></li>
                                    <li class="breadcrumb-item text-nowrap active" aria-current="page">Checkout</li>
                                </ol>
                            </nav>
                        </div>
                        <div class="order-lg-1 pe-lg-4 text-center text-lg-start">
                            <h1 class="h3 text-white mb-0">Checkout</h1>
                        </div>
                    </div>
                </div>
            </section>


            <section class="pb-5 mb-2 mb-md-4 pt-2">

                <div class="container">
                    <div class="row">
                        <section class="col-lg-8">
                          

                            <div class="steps steps-light pt-2 pb-3 mb-5">
                                <a class="step-item active" href="/shop-cart">
                                    <div class="step-progress">
                                        <span class="step-count">1</span>
                                    </div>
                                    <div class="step-label">
                                        <i class="ci-cart"></i>Cart</div>
                                </a>
                                <a class="step-item active current" href="/checkout-details">
                                    <div class="step-progress">
                                        <span class="step-count">2</span>
                                    </div>
                                    <div class="step-label">
                                        <i class="ci-user-circle"></i>Checkout/Payment
                                    </div>
                                </a>
                                <a class="step-item" href="/checkout-review">
                                    <div class="step-progress">
                                        <span class="step-count">3</span>
                                    </div>
                                    <div class="step-label">
                                        <i class="ci-check-circle"></i>Review</div>
                                </a>
                            </div>

                          
                            <div class="d-sm-flex justify-content-between align-items-center bg-secondary p-4 rounded-3 mb-grid-gutter">
                                <div class="d-flex align-items-center">
                                    <div class="img-thumbnail rounded-circle position-relative flex-shrink-0">
                                        <img class="rounded-circle" src="img/home/gemstone/avatar.jpg" width="90" alt="Susan Gardner"/>
                                    </div>
                                    <div class="ps-3">
                                        <h3 class="fs-base mb-0">Testing </h3><span class="text-primary fs-sm">Testing@Danish.com</span>
                                    </div>
                                </div>
                                <a class="btn btn-light btn-sm btn-shadow mt-3 mt-sm-0" href="/account-profile">
                                    <i class="ci-edit me-2"></i>Edit profile
                                </a>
                            </div>

                          
                            <h2 class="h6 pt-1 pb-3 mb-3 border-bottom">Shipping address</h2>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="mb-3">
                                        <label class="form-label" for="checkout-fn">Full Name</label>
                                        <input class="form-control" type="text" id="checkout-fn"/>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="mb-3">
                                        <label class="form-label" for="checkout-phone">Phone Number</label>
                                        <input class="form-control" type="text" id="checkout-phone"/>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="mb-3">
                                        <label class="form-label" for="checkout-email">E-mail Address</label>
                                        <input class="form-control" type="email" id="checkout-email"/>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="mb-3">
                                        <label class="form-label" for="checkout-fn">Delivery Address Label</label>
                                        <input class="form-control" type="text" id="checkout-fn"/>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="mb-3">
                                        <label class="form-label" for="checkout-country">Country</label>
                                        <select class="form-select" id="checkout-country">
                                            <option>Choose country</option>
                                            <option>India</option>
                                            <option>Canada</option>
                                            <option>France</option>
                                            <option>Germany</option>
                                            <option>Switzerland</option>
                                            <option>USA</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="mb-3">
                                        <label class="form-label" for="checkout-country">State</label>
                                        <select class="form-select" id="checkout-country">
                                            <option>Choose state</option>
                                            <option>Punjab</option>
                                            <option>Delhi</option>
                                            <option>Jammu</option>
                                            <option>Himachal</option>
                                            <option>Uttrakhand</option>
                                            <option>Utter Pradesh</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="mb-3">
                                        <label class="form-label" for="checkout-city">City</label>
                                        <select class="form-select" id="checkout-city">
                                            <option>Choose city</option>
                                            <option>Amritsar</option>
                                            <option>Pathankot</option>
                                            <option>Batala</option>
                                            <option>Jalandhar</option>
                                            <option>Khanna</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="mb-3">
                                        <label class="form-label" for="checkout-zip">ZIP Code</label>
                                        <input class="form-control" type="text" id="checkout-zip"/>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="mb-3">
                                        <label class="form-label" for="checkout-address-1">Address 1</label>
                                        <input class="form-control" type="text" id="checkout-address-1"/>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="mb-3">
                                        <label class="form-label" for="checkout-address-2">Address 2</label>
                                        <input class="form-control" type="text" id="checkout-address-2"/>
                                    </div>
                                </div>
                            </div>
                            <h6 class="mb-3 py-3 border-bottom">Billing address</h6>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" checked id="same-address"/>
                                    <label class="form-check-label" for="same-address">Same as shipping address</label>
                            </div>

                          
                            <h2 class="h6 py-3 mb-2 mt-3 border-top">Choose payment method</h2>
                            <div class="accordion mb-2" id="payment-method">
                                <div class="accordion-item">
                                    <h3 class="accordion-header">
                                        <a class="accordion-button" href="#card" data-bs-toggle="collapse">
                                            <i class="ci-card fs-lg me-2 mt-n1 align-middle"></i>Pay with Credit Card
                                        </a>
                                    </h3>
                                    <div class="accordion-collapse collapse show" id="card" data-bs-parent="#payment-method">
                                        <div class="accordion-body">
                                            <p class="fs-sm text-muted">We accept following credit cards:&nbsp;&nbsp;
                                                <img class="d-inline-block align-middle" src="img/home/gemstone/cards.png" width="187" alt="Cerdit Cards"/>
                                            </p>
                                            <div class="credit-card-wrapper"></div>
                                            <form class="credit-card-form row">
                                                <div class="mb-3 col-sm-6">
                                                    <input class="form-control" type="text" name="number" placeholder="Card Number" required/>
                                                </div>
                                                <div class="mb-3 col-sm-6">
                                                    <input class="form-control" type="text" name="name" placeholder="Full Name" required/>
                                                </div>
                                                <div class="mb-3 col-sm-3">
                                                    <input class="form-control" type="text" name="expiry" placeholder="MM/YY" required/>
                                                </div>
                                                <div class="mb-3 col-sm-3">
                                                    <input class="form-control" type="text" name="cvc" placeholder="CVC" required/>
                                                </div>
                                                <div class="col-sm-6">
                                                    <button class="btn btn-outline-primary d-block w-100 mt-0" type="submit">Submit</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h3 class="accordion-header"><a class="accordion-button collapsed" href="#paypal" data-bs-toggle="collapse"><i class="ci-paypal me-2 align-middle"></i>Pay with PayPal</a></h3>
                                    <div class="accordion-collapse collapse" id="paypal" data-bs-parent="#payment-method">
                                        <div class="accordion-body fs-sm">
                                            <p><span class='fw-medium'>PayPal</span> - the safer, easier way to pay</p>
                                            <form class="row needs-validation" method="post" novalidate>
                                                <div class="col-sm-6">
                                                    <div class="mb-3">
                                                        <input class="form-control" type="email" placeholder="E-mail" required/>
                                                            <div class="invalid-feedback">Please enter valid email address</div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="mb-3">
                                                        <input class="form-control" type="password" placeholder="Password" required/>
                                                            <div class="invalid-feedback">Please enter your password</div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="d-flex flex-wrap justify-content-between align-items-center"><a class="nav-link-style" href="#">Forgot password?</a>
                                                        <button class="btn btn-primary" type="submit">Log In</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                          
                            <div class="d-none d-lg-flex pt-4 mt-3">
                                <div class="w-50 pe-3">
                                    <a class="btn btn-secondary d-block w-100" href="/shop-cart">
                                        <i class="ci-arrow-left mt-sm-0 me-1"></i>
                                        <span class="d-none d-sm-inline">Back to Cart</span>
                                        <span class="d-inline d-sm-none">Back</span>
                                    </a>
                                </div>
                                <div class="w-50 ps-2">
                                    <a class="btn btn-primary d-block w-100" href="/checkout-review">
                                        <span class="d-none d-sm-inline">Review you order</span>
                                        <span class="d-inline d-sm-none">Next</span>
                                        <i class="ci-arrow-right mt-sm-0 ms-1"></i>
                                    </a>
                                </div>
                            </div>
                        </section>

                      
                        <aside class="col-lg-4 pt-4 pt-lg-0 ps-xl-5">
                            <div class="bgwhite radius8 shadow-lg p-4 ms-lg-auto">
                                <div class="py-2 px-xl-2">
                                    <div class="widget mb-3">
                                        <h2 class="widget-title text-center">Order summary</h2>
                                        <div class="d-flex align-items-center pb-2 border-bottom"><a class="d-block flex-shrink-0" href="/shop-single">
                                            <img src="img/home/shop/cart/widget/01.jpg" class="radius8" width="64" alt="Product"/></a>
                                            <div class="ps-2">
                                                <h6 class="widget-product-title"><a href="/shop-single">Amethyst (1490mg)</a></h6>
                                                <div class="widget-product-meta"><span class="text-primary me-2">Rs.<span>1500</span></span><span class="text-muted">x 1</span></div>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center py-2 border-bottom"><a class="d-block flex-shrink-0" href="/shop-single">
                                            <img src="img/home/shop/cart/widget/02.jpg" class="radius8" width="64" alt="Product"/></a>
                                            <div class="ps-2">
                                                <h6 class="widget-product-title"><a href="/shop-single">Citrine (1490mg)</a></h6>
                                                <div class="widget-product-meta"><span class="text-primary me-2">Rs.<span>2500</span></span><span class="text-muted">x 1</span></div>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center py-2 border-bottom"><a class="d-block flex-shrink-0" href="/shop-single">
                                            <img src="img/home/shop/cart/widget/03.jpg" class="radius8" width="64" alt="Product"/></a>
                                            <div class="ps-2">
                                                <h6 class="widget-product-title"><a href="/shop-single">Emerlad (1490mg)</a></h6>
                                                <div class="widget-product-meta"><span class="text-primary me-2">Rs.<span>3000</span></span><span class="text-muted">x 1</span></div>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center py-2 border-bottom"><a class="d-block flex-shrink-0" href="/shop-single">
                                            <img src="img/home/shop/cart/widget/04.jpg" class="radius8" width="64" alt="Product"/></a>
                                            <div class="ps-2">
                                                <h6 class="widget-product-title"><a href="/shop-single">Sapphire Yellow (1490mg)</a></h6>
                                                <div class="widget-product-meta"><span class="text-primary me-2">Rs.<span>2000</span></span><span class="text-muted">x 1</span></div>
                                            </div>
                                        </div>
                                    </div>

                                    <ul class="list-unstyled fs-sm pb-2 border-bottom">
                                        <li class="d-flex justify-content-between align-items-center">
                                            <span class="me-2">Subtotal:</span>
                                            <span class="text-end">Rs.<span>8000</span></span>
                                        </li>
                                        <li class="d-flex justify-content-between align-items-center">
                                            <span class="me-2">Shipping:</span>
                                            <span class="text-end">Rs.<span>100</span></span>
                                        </li>
                                        <li class="d-flex justify-content-between align-items-center">
                                            <span class="me-2">Taxes:</span>
                                            <span class="text-end">Rs.<span>50</span></span>
                                        </li>
                                    </ul>

                                    <h3 class="fw-normal text-center my-4">Rs.<span>9600</span></h3>
                                    <form class="needs-validation" method="post" novalidate>
                                        <div class="mb-3">
                                            <input class="form-control" type="text" placeholder="Promo code" required/>
                                                <div class="invalid-feedback">Please provide promo code.</div>
                                        </div>
                                        <button class="btn btn-outline-primary d-block w-100" type="submit">Apply promo code</button>
                                    </form>
                                </div>
                            </div>
                        </aside>
                    </div>

                    <div class="row d-lg-none">
                        <div class="col-lg-8">
                            <div class="d-flex pt-4 mt-3">
                                <div class="w-50 pe-3">
                                    <a class="btn btn-secondary d-block w-100" href="/shop-cart">
                                        <i class="ci-arrow-left mt-sm-0 me-1"></i>
                                        <span class="d-none d-sm-inline">Back to Cart</span>
                                        <span class="d-inline d-sm-none">Back</span>
                                    </a>
                                </div>
                                <div class="w-50 ps-2">
                                    <a class="btn btn-primary d-block w-100" href="/checkout-complete">
                                        <span class="d-none d-sm-inline">Proceed to Shipping</span>
                                        <span class="d-inline d-sm-none">Next</span>
                                        <i class="ci-arrow-right mt-sm-0 ms-1"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <FooterA/>
        </>
    )
}

export default CheckoutDetails
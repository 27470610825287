import React from 'react'
import  TinySlider  from 'tiny-slider-react';
import 'tiny-slider/dist/tiny-slider.css';
import "./Slider.css"
import { Link } from 'react-router-dom';
import Slide1 from "../Assests/img/home/gemstone/slide-1.png"; 
import Gem1 from '../Assests/img/home/gemstone/p-3.png'
import Gem2 from '../Assests/img/home/gemstone/p-4.png'
import Gem3 from '../Assests/img/home/gemstone/p-2.png'
import Gem4 from '../Assests/img/home/gemstone/p-1.png'
import Man1 from '../Assests/img/home/gemstone/man-1.png'
import Man2 from '../Assests/img/home/gemstone/man-2.png'

import GEMB1 from "../Assests/img/home/gemstone/b-1.png"
import GEMB2 from "../Assests/img/home/gemstone/b-2.png"
import GEMB3 from "../Assests/img/home/gemstone/b-3.png"
import GEMB4 from "../Assests/img/home/gemstone/b-4.png"
import GEMB5 from "../Assests/img/home/gemstone/b-5.png"
import Navbar from '../Components/Navbar/Navbar';
import NavbarTwo from '../Components/NavbarTwo/NavbarTwo';
import FooterA from '../Components/FooterA/FooterA';




// Update this path to the actual location of your image

const sliderSettings = {
  items: 1,
  controls: false,
  loop: false,
};

const sliderSettingsPremium = {
  items: 3,
  gutter: 16,
  controls: true,
  autoHeight: true,
  responsive: {
    0: { items: 1 },
    480: { items: 2 },
    720: { items: 3 },
    991: { items: 2 },
    1140: { items: 3 },
    1300: { items: 4 },
    1500: { items: 5 }
  }
};

const products = [
  { imgSrc:Gem1, title: 'Amethyst', meta: '(1490mg)', sku: '40311347889', price: 'Rs. 1500' },
  { imgSrc:Gem3, title: 'Citrine', meta: '(1490mg)', sku: '40311347889', price: 'Rs. 2500' },
  { imgSrc:Gem4, title: 'Emerald', meta: '(1490mg)', sku: '40311347889', price: 'Rs. 3500' },
  { imgSrc:Gem2, title: 'Amethyst', meta: '(1490mg)', sku: '40311347889', price: 'Rs. 2700' },
  { imgSrc:Gem1, title: 'Amethyst', meta: '(1490mg)', sku: '40311347889', price: 'Rs. 1500' },
  { imgSrc:Gem3, title: 'Citrine', meta: '(1490mg)', sku: '40311347889', price: 'Rs. 2500' },
  { imgSrc:Gem4, title: 'Emerald', meta: '(1490mg)', sku: '40311347889', price: 'Rs. 3500' },
  { imgSrc:Gem2, title: 'Amethyst', meta: '(1490mg)', sku: '40311347889', price: 'Rs. 2700' },
];


// Testimonials 
const testimonials = [
  {
    text: "Lorem Ipsumst Aptent quo est cumque ullamco lorem, neces sitatibus Erat Ipsumst Aptent quo est cumque ullamco lorem, neces ...",
    imgSrc:Man1,
    name: "James Anderson",
    title: "Process Engineer",
  },
  {
    text: "Lorem Ipsumst Aptent quo est cumque ullamco lorem, neces sitatibus Erat Ipsumst Aptent quo est cumque ullamco lorem, neces ...",
    imgSrc: Man2,
    name: "Ashlye Harnell",
    title: "Process Engineer",
  },
  {
    text: "Lorem Ipsumst Aptent quo est cumque ullamco lorem, neces sitatibus Erat Ipsumst Aptent quo est cumque ullamco lorem, neces ...",
    imgSrc:Man1,
    name: "James Anderson",
    title: "Process Engineer",
  },
  {
    text: "Lorem Ipsumst Aptent quo est cumque ullamco lorem, neces sitatibus Erat Ipsumst Aptent quo est cumque ullamco lorem, neces ...",
    imgSrc: Man2,
    name: "Ashlye Harnell",
    title: "Process Engineer",
  },
  {
    text: "Lorem Ipsumst Aptent quo est cumque ullamco lorem, neces sitatibus Erat Ipsumst Aptent quo est cumque ullamco lorem, neces ...",
    imgSrc:Man1,
    name: "James Anderson",
    title: "Process Engineer",
  },
  {
    text: "Lorem Ipsumst Aptent quo est cumque ullamco lorem, neces sitatibus Erat Ipsumst Aptent quo est cumque ullamco lorem, neces ...",
    imgSrc: Man2,
    name: "Ashlye Harnell",
    title: "Process Engineer",
  },
  // Add more testimonials as needed
];

const TestimonialsSliderSettings = {
  items: 2,
  gutter: 16,
  controls: false,
  autoHeight: true,
  responsive: {
    0: { items: 1 },
    480: { items: 1 },
    720: { items: 1 },
    991: { items: 2 },
    1140: { items: 2 },
    1300: { items: 2 },
    1500: { items: 2 },
  },
};

// Trusted Partners 
const images = [
 GEMB1,
 GEMB2,
 GEMB3,
 GEMB4,
 GEMB5,
 GEMB1,
 GEMB2,
 GEMB3,
 GEMB4,
 GEMB5,
 
];


const TrustedSliderSettings = {
  items: 2,
  gutter: 16,
  controls: false,
  autoHeight: true,
  responsive: {
    0: { items: 2 },
    480: { items: 2 },
    720: { items: 3 },
    991: { items: 2 },
    1140: { items: 3 },
    1300: { items: 5 },
    1500: { items: 5 }
  }
};



const FirstPage = () => {
  return (
   <>
  
<Navbar/>
<NavbarTwo/>
      <section class="bgLightGray py-5 slide-bg">
        <div class="container py-xl-2">
          <div class="row">
            <div class="col-xl-12 pt-xl-5">
            <TinySlider settings={sliderSettings}>
              <div>
                <div className="row align-items-center">
                  <div className="col-md-6 order-md-2">
                    <img className="d-block mx-auto" src={Slide1} alt="gemstone-1" />
                  </div>
                  <div className="col-lg-6 col-md-6 order-md-1 pt-4 pb-md-4 text-center text-md-start">
                    <h2 className="font16 letterSpacing2 radius4 bgwhite d-inline-block mb-4 px-2 py-1 text-uppercase from-bottom">
                      Welcome to the world of gemstone
                    </h2>
                    <h1 className="font70 from-bottom delay-1 mb-4">
                      Natural & Certified <span className="textRed">Gemstone</span> Shop
                    </h1>
                    <div className="d-table scale-up delay-4 mx-auto mx-md-0">
                      <a className="btn btn-outline-primary btn-shadow text-uppercase ms-1" href="/products">
                        Shop Now<i className="ci-arrow-right ms-2 me-n1"> </i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="row align-items-center">
                  <div className="col-md-6 order-md-2">
                    <img className="d-block mx-auto" src={Slide1} alt="gemstone-1" />
                  </div>
                  <div className="col-lg-6 col-md-6 order-md-1 pt-4 pb-md-4 text-center text-md-start">
                    <h2 className="font16 letterSpacing2 radius4 bgwhite d-inline-block mb-4 px-2 py-1 text-uppercase from-start">
                      Welcome to the world of gemstone
                    </h2>
                    <h1 className="font70 from-start delay-1 mb-4">
                      Natural & Certified <span className="textRed">Gemstone</span> Shop
                    </h1>
                    <div className="d-table scale-up delay-4 mx-auto mx-md-0">
                      <a className="btn btn-outline-primary btn-shadow text-uppercase ms-1" href="#">
                        Shop Now <i className="ci-arrow-right ms-2 me-n1"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="row align-items-center">
                  <div className="col-md-6 order-md-2">
                    <img className="d-block mx-auto" src={Slide1} alt="gemstone-1" />
                  </div>
                  <div className="col-lg-6 col-md-6 order-md-1 pt-4 pb-md-4 text-center text-md-start">
                    <h2 className="font16 letterSpacing2 radius4 bgwhite d-inline-block mb-4 px-2 py-1 text-uppercase scale-up">
                      Welcome to the world of gemstone
                    </h2>
                    <h1 className="font70 scale-up delay-1 mb-4">
                      Natural & Certified <span className="textRed">Gemstone</span> Shop
                    </h1>
                    <div className="d-table scale-up delay-4 mx-auto mx-md-0">
                      <a className="btn btn-outline-primary btn-shadow text-uppercase ms-1" href="/products">
                        Shop Now <i className="ci-arrow-right ms-2 me-n1"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </TinySlider>
            </div>
          </div>
        </div>
      </section>

      <section class="gemsCatagory py-5 bgGray">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="text-center">
                <h2 class="font16 letterSpacing2 bgwhite radius4 d-inline-block mb-3 px-2 py-1 text-uppercase scale-up">Category</h2>
                <h3 class="text-center font40">Shop Gemstones Collection</h3>
              </div>
            </div>
          </div>
          <div class="row gemstoneBoxs justify-content-center">
            <div class="col-lg-2 col-6 col-sm-4">
              <a href="javascript:;">
                <div class="wrapGemstone">
                  <div class="mb-3">
                    <img class="d-block mx-auto" src="img/home/gemstone/01.jpg" alt="01"/>
                  </div>
                  <h2 class="poppinsBold">Amethyst</h2>
                </div>
              </a>
            </div>
            <div class="col-lg-2 col-6 col-sm-4">
              <a href="javascript:;">
                <div class="wrapGemstone">
                  <div class="mb-3">
                    <img class="d-block mx-auto" src="img/home/gemstone/02.jpg" alt=""/>
                  </div>
                  <h2 class="poppinsBold">Cats Eye</h2>
                </div>
              </a>
            </div>
            <div class="col-lg-2 col-6 col-sm-4">
              <a href="javascript:;">
                <div class="wrapGemstone">
                  <div class="mb-3">
                    <img class="d-block mx-auto" src="img/home/gemstone/03.jpg" alt=""/>
                  </div>
                  <h2 class="poppinsBold">Citrine</h2>
                </div>
              </a>
            </div>
            <div class="col-lg-2 col-6 col-sm-4">
              <a href="javascript:;">
                <div class="wrapGemstone">
                  <div class="mb-3">
                    <img class="d-block mx-auto" src="img/home/gemstone/04.jpg" alt=""/>
                  </div>
                  <h2 class="poppinsBold">Coral</h2>
                </div>
              </a>
            </div>
            <div class="col-lg-2 col-6 col-sm-4">
              <a href="javascript:;">
                <div class="wrapGemstone">
                  <div class="mb-3">
                    <img class="d-block mx-auto" src="img/home/gemstone/05.jpg" alt=""/>
                  </div>
                  <h2 class="poppinsBold">Coral Triangular</h2>
                </div>
              </a>
            </div>
            <div class="col-lg-2 col-6 col-sm-4">
              <a href="javascript:;">
                <div class="wrapGemstone">
                  <div class="mb-3">
                    <img class="d-block mx-auto" src="img/home/gemstone/06.jpg" alt=""/>
                  </div>
                  <h2 class="poppinsBold">Emerald</h2>
                </div>
              </a>
            </div>
            <div class="col-lg-2 col-6 col-sm-4">
              <a href="javascript:;">
                <div class="wrapGemstone">
                  <div class="mb-3">
                    <img class="d-block mx-auto" src="img/home/gemstone/07.jpg" alt=""/>
                  </div>
                  <h2 class="poppinsBold">Hessonite</h2>
                </div>
              </a>
            </div>
            <div class="col-lg-2 col-6 col-sm-4">
              <a href="javascript:;">
                <div class="wrapGemstone">
                  <div class="mb-3">
                    <img class="d-block mx-auto" src="img/home/gemstone/08.jpg" alt=""/>
                  </div>
                  <h2 class="poppinsBold">Iolite</h2>
                </div>
              </a>
            </div>
            <div class="col-lg-2 col-6 col-sm-4">
              <a href="javascript:;">
                <div class="wrapGemstone">
                  <div class="mb-3">
                    <img class="d-block mx-auto" src="img/home/gemstone/09.jpg" alt=""/>
                  </div>
                  <h2 class="poppinsBold">Opal</h2>
                </div>
              </a>
            </div>
            <div class="col-lg-2 col-6 col-sm-4">
              <a href="javascript:;">
                <div class="wrapGemstone">
                  <div class="mb-3">
                    <img class="d-block mx-auto" src="img/home/gemstone/10.jpg" alt=""/>
                  </div>
                  <h2 class="poppinsBold">Pearl</h2>
                </div>
              </a>
            </div>
            <div class="col-lg-2 col-6 col-sm-4">
              <a href="javascript:;">
                <div class="wrapGemstone">
                  <div class="mb-3">
                    <img class="d-block mx-auto" src="img/home/gemstone/11.jpg" alt=""/>
                  </div>
                  <h2 class="poppinsBold">Ruby</h2>
                </div>
              </a>
            </div>
            <div class="col-lg-2 col-6 col-sm-4">
              <a href="javascript:;">
                <div class="wrapGemstone">
                  <div class="mb-3">
                    <img class="d-block mx-auto" src="img/home/gemstone/12.jpg" alt=""/>
                  </div>
                  <h2 class="poppinsBold">Ruby</h2>
                </div>
              </a>
            </div>
            <div class="col-lg-2 col-6 col-sm-4">
              <a href="javascript:;">
                <div class="wrapGemstone">
                  <div class="mb-3">
                    <img class="d-block mx-auto" src="img/home/gemstone/13.jpg" alt=""/>
                  </div>
                  <h2 class="poppinsBold">Sapphire</h2>
                </div>
              </a>
            </div>
            <div class="col-lg-2 col-6 col-sm-4">
              <a href="javascript:;">
                <div class="wrapGemstone">
                  <div class="mb-3">
                    <img class="d-block mx-auto" src="img/home/gemstone/14.jpg" alt=""/>
                  </div>
                  <h2 class="poppinsBold">Sapphire Blue</h2>
                </div>
              </a>
            </div>
            <div class="col-lg-2 col-6 col-sm-4">
              <a href="javascript:;">
                <div class="wrapGemstone">
                  <div class="mb-3">
                    <img class="d-block mx-auto" src="img/home/gemstone/15.jpg" alt=""/>
                  </div>
                  <h2 class="poppinsBold">Sapphire Pink</h2>
                </div>
              </a>
            </div>
            <div class="col-lg-2 col-6 col-sm-4">
              <a href="javascript:;">
                <div class="wrapGemstone">
                  <div class="mb-3">
                    <img class="d-block mx-auto" src="img/home/gemstone/16.jpg" alt=""/>
                  </div>
                  <h2 class="poppinsBold">Sapphire White</h2>
                </div>
              </a>
            </div>
            <div class="col-lg-2 col-6 col-sm-4">
              <a href="javascript:;">
                <div class="wrapGemstone">
                  <div class="mb-3">
                    <img class="d-block mx-auto" src="img/home/gemstone/17.jpg" alt=""/>
                  </div>
                  <h2 class="poppinsBold">Sapphire Yellow</h2>
                </div>
              </a>
            </div>
            <div class="col-lg-2 col-6 col-sm-4">
              <a href="javascript:;">
                <div class="wrapGemstone">
                  <div class="mb-3">
                    <img class="d-block mx-auto" src="img/home/gemstone/18.jpg" alt=""/>
                  </div>
                  <h2 class="poppinsBold">Topaz</h2>
                </div>
              </a>
            </div>
            <div class="col-lg-2 col-6 col-sm-4">
              <a href="javascript:;">
                <div class="wrapGemstone">
                  <div class="mb-3">
                    <img class="d-block mx-auto" src="img/home/gemstone/19.jpg" alt=""/>
                  </div>
                  <h2 class="poppinsBold">Turquoise</h2>
                </div>
              </a>
            </div>
            <div class="col-lg-2 col-6 col-sm-4">
              <a href="javascript:;">
                <div class="wrapGemstone">
                  <div class="mb-3">
                    <img class="d-block mx-auto" src="img/home/gemstone/20.jpg" alt=""/>
                  </div>
                  <h2 class="poppinsBold">Zircon</h2> 
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>


      <section class="gemsCatagory py-5 bgLightGray">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="text-center">
                <h2 class="font16 letterSpacing2 bgwhite radius4 d-inline-block mb-3 px-2 py-1 text-uppercase scale-up">Premium Collections</h2>
                <h3 class="text-center font40">Premium Gemstone Collection </h3>
              </div>
            </div>
          </div>

          
          <div className="tns-carousel tns-controls-static tns-controls-outside tns-nav-enabled mt-4">
      <TinySlider settings={sliderSettingsPremium}>
        {products.map((product, index) => (
          <div key={index} className="tns-item">
            <div className="card product-card card-static pb-3">
              <button className="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title="Add to wishlist">
                <i className="ci-heart"></i>
              </button>
              <a className="card-img-top d-block overflow-hidden pt-4 pb-2" href="javascript:;">
                <img className="d-block mx-auto" src={product.imgSrc} alt={product.title} />
              </a>
              <div className="card-body py-2">
                <h3 className="product-title fs-sm text-truncate mb-2">
                  <a href="javascript:;">{product.title}</a>
                  <span className="product-meta">{product.meta}</span>
                </h3>
                <h3 className="product-title fs-sm text-truncate">
                  <span className="product-meta">SKU: {product.sku}</span>
                </h3>
                <div className="textRed font18">
                  {product.price}
                </div>
              </div>
              <div className="product-floating-btn">
                <button className="btn btn-primary btn-shadow btn-sm" type="button">+<i className="ci-cart fs-base ms-1"></i></button>
              </div>
            </div>
          </div>
        ))}
      </TinySlider>
    </div>
        </div>
      </section>

      <section class="py-5 bgGray">
        <div class="pt-5">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="text-center">
                  <h2 class="font16 letterSpacing2 bgwhite radius4 d-inline-block mb-3 px-2 py-1 text-uppercase scale-up">Gemstone THAT RESPECTS NATURE AND HUMANKIND</h2>
                  <h3 class="text-center font60">Expertise and Trust catered with Gemstone Where Gems Become Legacies</h3>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="abt-Btnn">
                  <a href="javascript:;">
                    <img class="d-block" src="img/home/gemstone/about-btn.jpg" alt="about-btn"/>
                    <span>About us</span>
                  </a>
                </div>
                <div class="abt9GemText text-center">
                  <h3 class="text-uppercase">About 9Gem<span>.com</span></h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section class="py-3 bgBlue">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12">
              <div class="d-sm-flex d-block gap-4 justify-content-center align-items-center">
                <h2 class="font18 text-white mb-3 mb-sm-0">Super discount for your</h2>
                <h3 class="font18 text-uppercase text-white mb-3 mb-sm-0">first purchase.</h3>
                <div class="font14 fontWeight700 textRed redBorder text-uppercase py-2 px-3 text-center">free15first</div>
                <h3 class="font18 text-white mb-0 mt-3 mt-sm-0">Use discount code in checkout! </h3>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section class="py-5">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 mb-grid-gutter">
              <div class="d-block radius20 bgGray">
                <div class="pt-5 py-sm-5 px-4 ps-md-5 pe-md-0 text-center text-sm-start">
                  <h2 class="textRed font16 mt-3">9 Gem</h2>
                  <p class="pb-2 mb-0 font30 textDarkBlue">NEW ARRIVALS <br/> ON THE SHOP</p>
                  <p class="text-muted mb-4">Up to 45% Off on New arrival <br/> Gemstone</p>
                  <a class="btn btn-outline-primary btn-shadow text-uppercase" href="/products">Shop Now</a>
                </div>
                <img class="d-block w-100 mx-auto radius20" src="img/home/gemstone/gem-1.jpg" alt=""/>
              </div>
            </div>
            <div class="col-lg-6 mb-grid-gutter">
              <div class="row">
                  <div class="col-lg-12 mb-grid-gutter">
                    <div class="d-block d-sm-flex justify-content-between align-items-center radius20 bgGray">
                      <div class="pt-5 py-sm-5 px-4 ps-md-5 pe-md-0 text-center text-sm-start">
                        <h2 class="textRed font16">9 Gem</h2>
                        <p class="pb-2 font30 textDarkBlue">Just Lunched Desk The Hals</p>
                        <a class="btn btn-outline-primary btn-shadow text-uppercase" href="/products">Shop Now</a>
                      </div>
                      <img class="d-block w-100 mx-auto radius20" src="img/home/gemstone/gem-2.jpg" alt=""/>
                    </div>
                  </div>
                  <div class="col-lg-12 mb-grid-gutter">
                    <div class="d-block d-sm-flex justify-content-between align-items-center radius20 bgLightPink">
                      <div class="pt-5 py-sm-5 px-4 ps-md-5 pe-md-0 text-center text-sm-start">
                        <h2 class="textRed font16">9 Gem</h2>
                        <p class="pb-2 font30 textDarkBlue">Hottest Selling <br/> Gemstone</p> 
                        <a class="btn btn-outline-primary btn-shadow text-uppercase" href="/products">Shop Now</a>
                      </div>
                      <img class="d-block mx-auto radius20" src="img/home/gemstone/gem-3.jpg" alt=""/>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section class="py-5 bgGray">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="selectionBuying">
                <h2 class="pb-2 font60 textDarkBlue"><span class="fontWeight700 textRed">Gemstone</span> <br/> Selections & Buying Made Easy for you</h2>
                <a class="btn btn-outline-primary btn-shadow text-uppercase" href="/products">Shop Now</a>
              </div>
            </div>
            <div class="col-lg-6">
              <div>
                <img class="d-block mx-auto radius20" src="img/home/gemstone/gems-group.jpg" alt=""/>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="py-5">
      <div className="container">
        <div className=" tns-carousel tns-controls-static tns-controls-outside tns-nav-enabled mt-4" >
          <TinySlider settings={TrustedSliderSettings}>
            {images.map((src, index) => (
              <div key={index}>
                <img className="d-block mx-auto" src={src} alt={`Image ${index + 1}`} />
              </div>
            ))}
          </TinySlider>
        </div>
      </div>
    </section>

      <section className="py-5 bgGray">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="text-center">
              <h2 className="font16 letterSpacing2 bgwhite radius4 d-inline-block mb-3 px-2 py-1 text-uppercase scale-up">Testimonials</h2>
              <h3 className="text-center font40">Our Happy Clients!</h3>
            </div>
          </div>
        </div>
        <div className=" tns-carousel tns-controls-static tns-controls-outside tns-nav-enabled mt-4">
      
          <TinySlider settings={TestimonialsSliderSettings}>
            {testimonials.map((testimonial, index) => (
              <div key={index}>
                <div className="mainTestContent">
                  <h2 className="testiCont">{testimonial.text}</h2>
                  <ul className="list-unstyled clearfix">
                    <li>
                      <img className="d-block mx-auto" src={testimonial.imgSrc} alt={testimonial.name} />
                    </li>
                    <li>
                      <h2>{testimonial.name}</h2>
                      <p>{testimonial.title}</p>
                    </li>
                  </ul>
                </div>
              </div>
            ))}
          </TinySlider>
        </div>
      </div>
    </section>


      <section class="contactInfo" data-black-overlay="9">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div>
                <h2 class="font16 letterSpacing2 bgwhite radius4 d-inline-block mb-3 px-2 py-1 text-uppercase scale-up">DROP US A CALL!</h2>
                <h3 class="pb-2 font60 text-white">Not Sure What <br/>
                    <span class="fontWeight700">Gemstone</span> is Good for You?
                </h3>
              </div>
              <div class="text-center mt-4 bookCall">
                  <a class="btn btn-outline-primary btn-shadow text-uppercase" href="#"> 
                    <img src="img/home/gemstone/call.png" class="mr-2 d-none d-sm-inline-block" alt="image"/>Call Now
                    <i class="ci-arrow-right ms-2 me-n1"></i>
                  </a>
                  <a class="btn btn-outline-primary btn-shadow text-uppercase ms-3" href="#"> 
                    <img src="img/home/gemstone/call.png" class="mr-2 d-none d-sm-inline-block" alt="image"/>Request A Call Back
                    <i class="ci-arrow-right ms-2 me-n1"></i>
                  </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="pt-5 pb-4 pb-lg-5">
        <div class="container pt-lg-3">
          <div class="row pt-lg-2 text-left text-md-center">
            <div class="col-lg-3 col-sm-6 mb-grid-gutter">
              <div class="d-inline-flex align-items-center text-start">
                <img src="img/home/gemstone/icon-1.png" width="48" alt="Quality Guarantee"/>
                <div class="ps-3">
                  <h6 class="text-light fontWeight700 font18 mb-1">Fast Shipping</h6>
                  <p class="text-muted mb-0">Dependable delivery <br/> service  for rapid shipping.</p>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6 mb-grid-gutter">
              <div class="d-inline-flex align-items-center text-start">
                <img src="img/home/gemstone/icon-2.png" width="48" alt="Customer Support"/>
                <div class="ps-3">
                  <h6 class="text-light fontWeight700 font18 mb-1">Flexible Payment</h6>
                  <p class="text-muted mb-0">Pay with Multiple Credit <br/> Cards.</p>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6 mb-grid-gutter">
              <div class="d-inline-flex align-items-center text-start">
                <img src="img/home/gemstone/icon-3.png" width="48" alt="Free Updates"/>
                <div class="ps-3">
                  <h6 class="text-light fontWeight700 font18 mb-1">Money Guarantee</h6>
                  <p class="text-muted mb-0">Within 7 days for an <br/> exchange.</p>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6 mb-grid-gutter">
              <div class="d-inline-flex align-items-center text-start">
                <img src="img/home/gemstone/icon-4.png" width="48" alt="Secure Payments"/>
                <div class="ps-3">
                  <h6 class="text-light fontWeight700 font18 mb-1">Online Support</h6>
                  <p class="text-muted mb-0">We are available for <br/> 24x7</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
   <FooterA/>
   </>
  )
}

export default FirstPage
import React from 'react';
import CardsAlt from "../../Assests/img/home/gemstone/cards-alt.png"
import { Link } from 'react-router-dom';

const FooterA = () => {
  return (
  <>
   <footer class="footer pt-5">
      <div class="container">
        <div class="row pb-2">
          <div class="col-md-2 col-4 col-sm-6">
            <div class="widget widget-links widget-light pb-2 mb-4">
              <h3 class="widget-title">Products</h3>
              <ul class="widget-list">
                <li class="widget-list-item"><a class="widget-list-link" href="#">Amethyst</a></li>
                <li class="widget-list-item"><a class="widget-list-link" href="#">Cats Eye</a></li>
                <li class="widget-list-item"><a class="widget-list-link" href="#">Citrine</a></li>
                <li class="widget-list-item"><a class="widget-list-link" href="#">Coral</a></li>
                <li class="widget-list-item"><a class="widget-list-link" href="#">Coral Triangular</a></li>
                <li class="widget-list-item"><a class="widget-list-link" href="#">Emerald</a></li>
                <li class="widget-list-item"><a class="widget-list-link" href="#">Hessonite</a></li>
              </ul>
            </div>
          </div>
          <div class="col-md-2 col-4 col-sm-6">
            <div class="widget widget-links widget-light pb-2 mb-4">
              <h3 class="widget-title invisible d-none d-md-block">Products</h3>
              <ul class="widget-list">
                <li class="widget-list-item"><a class="widget-list-link" href="#">Iolite</a></li>
                <li class="widget-list-item"><a class="widget-list-link" href="#">Opal</a></li>
                <li class="widget-list-item"><a class="widget-list-link" href="#">Pearl</a></li>
                <li class="widget-list-item"><a class="widget-list-link" href="#">Pearl Natural</a></li>
                <li class="widget-list-item"><a class="widget-list-link" href="#">Ruby</a></li>
                <li class="widget-list-item"><a class="widget-list-link" href="#">Sapphire</a></li>
                <li class="widget-list-item"><a class="widget-list-link" href="#">Sapphire Blue</a></li>
              </ul>
            </div>
          </div>
          <div class="col-md-2 col-4 col-sm-6">
            <div class="widget widget-links widget-light pb-2 mb-4">
              <h3 class="widget-title invisible d-none d-md-block">Products</h3>
              <ul class="widget-list">
                <li class="widget-list-item"><a class="widget-list-link" href="#">Sapphire Pink</a></li>
                <li class="widget-list-item"><a class="widget-list-link" href="#">Sapphire White</a></li>
                <li class="widget-list-item"><a class="widget-list-link" href="#">Sapphire Yellow</a></li>
                <li class="widget-list-item"><a class="widget-list-link" href="#">Topaz</a></li>
                <li class="widget-list-item"><a class="widget-list-link" href="#">Turquoise</a></li>
                <li class="widget-list-item"><a class="widget-list-link" href="#">Zircon</a></li>
              </ul>
            </div>
          </div>
          <div class="col-md-3 col-sm-6">
            <div class="widget widget-links widget-light pb-2 mb-4">
              <h3 class="widget-title">Policies</h3>
              <ul class="widget-list">
                <li class="widget-list-item"><a class="widget-list-link" href="#">Terms and Conditions</a></li>
                <li class="widget-list-item"><a class="widget-list-link" href="#">Replacement & Refunds</a></li>
                <li class="widget-list-item"><a class="widget-list-link" href="#">Shipping Policy</a></li>
                <li class="widget-list-item"><a class="widget-list-link" href="#">Payment Method</a></li>
              </ul>
            </div>
            <div class="widget widget-links widget-light pb-2 mb-4">
              <h3 class="widget-title">NEWSLETTER</h3>
              <form class="subscription-form shadow">
                <div class="input-group flex-nowrap radius4">
                  <i class="ci-mail position-absolute top-50 translate-middle-y text-muted fs-base ms-3"></i>
                  <input class="form-control radius4" type="email" name="EMAIL" placeholder="Your email" required/>
                  <button class="btn btn-primary" type="submit" name="subscribe"><i class="ci-arrow-right"></i></button>
                </div>
              </form>
            </div>
          </div>
          <div class="col-md-3">
            <div class="widget pb-2 mb-4">
              <h3 class="widget-title text-light pb-1">Contact Info </h3>
              <ul class="widget-list">
                <li class="widget-list-item">
                  <span class="widget-list-link">59 Krishna Square 2, Amritsar-143001 INDIA</span>
                </li>
                <li class="widget-list-item">
                  <a class="widget-list-link">Mail Us : info@9gem.com</a>
                </li>
                <li class="widget-list-item">
                  <a class="widget-list-link">Phone : +919216223355</a>
                </li>
              </ul>
            </div>
            <div class="mb-3">
              <a class="btn-social bs-twitter me-2 mb-2" href="#"><i class="ci-twitter"></i></a>
              <a class="btn-social bs-facebook me-2 mb-2" href="#"><i class="ci-facebook"></i></a>
              <a class="btn-social bs-instagram me-2 mb-2" href="#"><i class="ci-instagram"></i></a>
              <a class="btn-social bs-pinterest me-2 mb-2" href="#"><i class="ci-pinterest"></i></a>
              <a class="btn-social bs-youtube me-2 mb-2" href="#"><i class="ci-youtube"></i></a>
            </div>
          </div>
        </div>
      </div>
      <div class="pt-5 bgGray">
        <div class="container">
          <div class="row pb-2">
            <div class="col-md-6 text-center text-md-start mb-4">
              <div class="textDarkBlue text-center text-md-start">© 2024 Copyright <span class="fontWeight700">9 Gems</span> All Rights Reserved.</div>
            </div>
            <div class="col-md-6 text-center text-md-end mb-4">
              <img class="d-inline-block" src={CardsAlt} width="187" alt="Payment methods"/>
            </div>
          </div>
        </div>
      </div>
    </footer>

  </>
  )
}

export default FooterA
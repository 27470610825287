import React from 'react'

const Register = () => {
  return (
   <>
    <section class="d-flex justify-content-center align-items-center">
      <div class="lgoinSignMain my-4">
        <div class="d-flex justify-content-center">
          <a href="/">
            <img src="img/logo-dark.png" width="100" alt="9 Gem"/>
          </a>
        </div>
        <div class="card my-3">
          <div class="card-body">
            <h5><i class="ci-user me-2 mt-n1"></i>Create Account</h5>
            <form>
              <div class="mb-3">
                <label class="form-label font16">Full name</label>
                <input class="form-control" type="text" placeholder="Arjun Kumar"/>
              </div>
              <div class="mb-3">
                <label class="form-label font16">Phone number</label>
                <input class="form-control" type="text" placeholder="8969161226"/>
              </div>
              <div class="mb-3">
                <label class="form-label font16">Email address</label>
                <input class="form-control" type="email" placeholder="gemlab@gmail.com"/>
              </div>
              <div class="mb-3">
                <label class="form-label font16">Password</label>
                <div class="password-toggle">
                  <input class="form-control" type="password"/>
                  <label class="password-toggle-btn" aria-label="Show/hide password">
                    <input class="password-toggle-check" type="checkbox"/>
                    <span class="password-toggle-indicator"></span>
                  </label>
                </div>
                <span class="font14 textDarkBlue">Passwords must be at least 6 characters.</span>
              </div>
              <a href="" class="btn btn-primary btn-shadow d-block w-100" type="submit">Continue</a>
            </form>
          </div>
        </div>
        <div class="text-center">
          <p class="mb-3 font14"> Already have an account? </p>
          <p class="mb-0"><a href="/login" class="btn btn-outline-dark btn-shadow d-block w-100" type="submit">Click to Login</a></p>
        </div>
      </div>
    </section>
   </>
  )
}

export default Register
import React from 'react';
import Navbar from '../Components/Navbar/Navbar';
import NavbarTwo from '../Components/NavbarTwo/NavbarTwo';
import FooterA from '../Components/FooterA/FooterA';

const CheckoutReview = () => {
  return (
   <>
   <Navbar/>
   <NavbarTwo/>
     <section class="page-title-overlap bgBlue pt-4 pb-5">
        <div class="container pt-2 pb-3 pt-lg-3 pb-lg-4">
          <div class="d-lg-flex justify-content-between pb-3">
              <div class="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
                    <li class="breadcrumb-item">
                      <a class="text-nowrap" href="/"><i class="ci-home"></i>Home</a>
                    </li>
                    <li class="breadcrumb-item text-nowrap"><a href="/products">Products</a></li>
                    <li class="breadcrumb-item text-nowrap active" aria-current="page">Checkout</li>
                  </ol>
                </nav>
              </div>
              <div class="order-lg-1 pe-lg-4 text-center text-lg-start">
                <h1 class="h3 text-white mb-0">Checkout</h1>
              </div>
          </div>
        </div>
      </section>


      <section class="pb-5 mb-2 mb-md-4 pt-2">
        
        <div class="container">
          <div class="row">
            <section class="col-lg-8">

              <div class="steps steps-light pt-2 pb-3 mb-5">
                <a class="step-item active" href="/shop-cart">
                  <div class="step-progress">
                    <span class="step-count">1</span>
                  </div>
                  <div class="step-label">
                  <i class="ci-cart"></i>Cart</div>
                </a>
                <a class="step-item active" href="/checkout-details">
                  <div class="step-progress">
                    <span class="step-count">2</span>
                  </div>
                  <div class="step-label">
                    <i class="ci-user-circle"></i>Checkout/Payment
                  </div>
                </a>
                <a class="step-item active current" href="/checkout-review">
                  <div class="step-progress">
                    <span class="step-count">3</span>
                  </div>
                  <div class="step-label">
                  <i class="ci-check-circle"></i>Review</div>
                </a>
              </div>


              <h2 class="h6 pt-1 pb-3 mb-3 border-bottom">Review your order</h2>
             
              <div class="d-sm-flex justify-content-between my-4 pb-3 border-bottom">
                <div class="d-sm-flex text-center text-sm-start"><a class="d-inline-block flex-shrink-0 mx-auto me-sm-4" href="/shop-single"><img src="img/home/shop/cart/01.jpg" class="radius8" width="160" alt="Product"/></a>
                  <div class="pt-2">
                    <h3 class="product-title fs-base mb-2"><a href="/shop-single">Amethyst (1490mg)</a></h3>
                    <div class="fs-sm"><span class="text-muted me-2">SKU:</span>40311347889</div>
                    <div class="fs-sm"><span class="text-muted me-2">Ratti:</span>12+</div>
                    <div class="fs-lg text-primary pt-2">Rs..<span>1500</span></div>
                  </div>
                </div>
                <div class="pt-2 pt-sm-0 ps-sm-3 mx-auto mx-sm-0 text-center text-sm-end" style={{maxWidth: '9rem'}}>
                  <p class="mb-0"><span class="text-muted fs-sm">Quantity:</span><span>&nbsp;1</span></p>
                  <button class="px-0 py-3 border-0 bgwhite d-block w-100 text-danger" type="button"><i class="ci-edit me-2"></i><span class="fs-sm">Edit</span></button>
                </div>
              </div>
             
              <div class="d-sm-flex justify-content-between my-4 pb-3 border-bottom">
                <div class="d-sm-flex text-center text-sm-start"><a class="d-inline-block flex-shrink-0 mx-auto me-sm-4" href="/shop-single"><img src="img/home/shop/cart/02.jpg" class="radius8" width="160" alt="Product"/></a>
                  <div class="pt-2">
                    <h3 class="product-title fs-base mb-2"><a href="/shop-single">Citrine (1490mg)</a></h3>
                    <div class="fs-sm"><span class="text-muted me-2">SKU:</span>40311347889</div>
                    <div class="fs-sm"><span class="text-muted me-2">Ratti:</span>12+</div>
                    <div class="fs-lg text-primary pt-2">Rs..<span>2500</span></div>
                  </div>
                </div>
                <div class="pt-2 pt-sm-0 ps-sm-3 mx-auto mx-sm-0 text-center text-sm-end" style={{maxWidth: '9rem'}}>
                  <p class="mb-0"><span class="text-muted fs-sm">Quantity:</span><span>&nbsp;1</span></p>
                  <button class="px-0 py-3 border-0 bgwhite d-block w-100 text-danger" type="button"><i class="ci-edit me-2"></i><span class="fs-sm">Edit</span></button>
                </div>
              </div>

              <div class="d-sm-flex justify-content-between my-4 pb-3 border-bottom">
                <div class="d-sm-flex text-center text-sm-start"><a class="d-inline-block flex-shrink-0 mx-auto me-sm-4" href="/shop-single"><img src="img/home/shop/cart/03.jpg" class="radius8" width="160" alt="Product"/></a>
                  <div class="pt-2">
                    <h3 class="product-title fs-base mb-2"><a href="/shop-single">Emerlad (1490mg)</a></h3>
                    <div class="fs-sm"><span class="text-muted me-2">SKU:</span>40311347889</div>
                    <div class="fs-sm"><span class="text-muted me-2">Ratti:</span>12+</div>
                    <div class="fs-lg text-primary pt-2">Rs..<span>3000</span></div>
                  </div>
                </div>
                <div class="pt-2 pt-sm-0 ps-sm-3 mx-auto mx-sm-0 text-center text-sm-end" style={{maxWidth: '9rem'}}>
                  <p class="mb-0"><span class="text-muted fs-sm">Quantity:</span><span>&nbsp;1</span></p>
                  <button class="px-0 py-3 border-0 bgwhite d-block w-100 text-danger" type="button"><i class="ci-edit me-2"></i><span class="fs-sm">Edit</span></button>
                </div>
              </div>
              
              <div class="d-sm-flex justify-content-between my-4 pb-3 border-bottom">
                <div class="d-sm-flex text-center text-sm-start"><a class="d-inline-block flex-shrink-0 mx-auto me-sm-4" href="/shop-single"><img src="img/home/shop/cart/04.jpg" class="radius8" width="160" alt="Product"/></a>
                  <div class="pt-2">
                    <h3 class="product-title fs-base mb-2"><a href="/shop-single">Sapphire Yellow (1490mg)</a></h3>
                    <div class="fs-sm"><span class="text-muted me-2">SKU:</span>40311347889</div>
                    <div class="fs-sm"><span class="text-muted me-2">Ratti:</span>12+</div>
                    <div class="fs-lg text-primary pt-2">Rs..<span>2000</span></div>
                  </div>
                </div>
                <div class="pt-2 pt-sm-0 ps-sm-3 mx-auto mx-sm-0 text-center text-sm-end" style={{maxWidth: '9rem'}}>
                  <p class="mb-0"><span class="text-muted fs-sm">Quantity:</span><span>&nbsp;1</span></p>
                  <button class="px-0 py-3 border-0 bgwhite d-block w-100 text-danger" type="button"><i class="ci-edit me-2"></i><span class="fs-sm">Edit</span></button>
                </div>
              </div>
            
              <div class="bg-secondary rounded-3 px-4 pt-4 pb-2">
                <div class="row">
                  <div class="col-sm-6">
                    <h4 class="h6">Shipping to:</h4>
                    <ul class="list-unstyled fs-sm">
                      <li><span class="text-muted">Client:&nbsp;</span>Susan Gardner</li>
                      <li><span class="text-muted">Address:&nbsp;</span>44 Shirley Ave. West Chicago, IL 60185, USA</li>
                      <li><span class="text-muted">Phone:&nbsp;</span>+1 (808) 764 554 330</li>
                    </ul>
                  </div>
                  <div class="col-sm-6">
                    <h4 class="h6">Payment method:</h4>
                    <ul class="list-unstyled fs-sm">
                      <li><span class="text-muted">Credit Card:&nbsp;</span>**** **** **** 5300</li>
                    </ul>
                  </div>
                </div>
              </div>
            
              <div class="d-none d-lg-flex pt-4">
                <div class="w-50 pe-3"><a class="btn btn-secondary d-block w-100" href="/checkout-details"><i class="ci-arrow-left mt-sm-0 me-1"></i><span class="d-none d-sm-inline">Back to Payment</span><span class="d-inline d-sm-none">Back</span></a></div>
                <div class="w-50 ps-2"><a class="btn btn-primary d-block w-100" href="/checkout-complete"><span class="d-none d-sm-inline">Complete order</span><span class="d-inline d-sm-none">Complete</span><i class="ci-arrow-right mt-sm-0 ms-1"></i></a></div>
              </div>
            </section>

            <aside class="col-lg-4 pt-4 pt-lg-0 ps-xl-5">
              <div class="bgwhite radius8 shadow-lg p-4 ms-lg-auto">
                <div class="py-2 px-xl-2">
                  <div class="widget mb-3">
                    <h2 class="widget-title text-center">Order summary</h2>
                  </div>

                  <ul class="list-unstyled fs-sm pb-2 border-bottom">
                    <li class="d-flex justify-content-between align-items-center">
                      <span class="me-2">Subtotal:</span>
                      <span class="text-end">Rs.<span>8000</span></span>
                    </li>
                    <li class="d-flex justify-content-between align-items-center">
                      <span class="me-2">Shipping:</span>
                      <span class="text-end">Rs.<span>100</span></span>
                    </li>
                    <li class="d-flex justify-content-between align-items-center">
                      <span class="me-2">Taxes:</span>
                      <span class="text-end">Rs.<span>50</span></span>
                    </li>
                  </ul>

                  <h3 class="fw-normal text-center my-4">Rs.<span>9600</span></h3>
                  <form class="needs-validation" method="post" novalidate>
                    <div class="mb-3">
                      <input class="form-control" type="text" placeholder="Promo code" required/>
                      <div class="invalid-feedback">Please provide promo code.</div>
                    </div>
                    <button class="btn btn-outline-primary d-block w-100" type="submit">Apply promo code</button>
                  </form>
                </div>
              </div>
            </aside>
          </div>

        
          <div class="row d-lg-none">
            <div class="col-lg-8">
              <div class="d-flex pt-4 mt-3">
                <div class="w-50 pe-3">
                  <a class="btn btn-secondary d-block w-100" href="/shop-cart">
                    <i class="ci-arrow-left mt-sm-0 me-1"></i>
                    <span class="d-none d-sm-inline">Back to Cart</span>
                    <span class="d-inline d-sm-none">Back</span>
                  </a>
                </div>
                <div class="w-50 ps-2">
                  <a class="btn btn-primary d-block w-100" href="/checkout-review">
                    <span class="d-none d-sm-inline">Proceed to Shipping</span>
                    <span class="d-inline d-sm-none">Next</span>
                    <i class="ci-arrow-right mt-sm-0 ms-1"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
      <FooterA/>
   </>
  )
}

export default CheckoutReview